import { createRouter, createWebHistory } from 'vue-router'
import Dashborad from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Thread from '../views/Thread.vue'
import Process from '../views/Process.vue'
import Api from '../views/Api.vue'
import Github from '../views/Github.vue'

import User from '../views/admin/User.vue'
import Whatsapp from '../views/admin/Whatsapp.vue'
import WhatsappTest from '../views/admin/WhatsappTest.vue'
import UserAgent from '../views/admin/UserAgent.vue'
import Message from '../views/admin/Message.vue'
import MessageTest from '../views/admin/MessageTest.vue'
import Webhook from '../views/admin/Webhook.vue'
import Parameter from '../views/admin/Parameter.vue'
import Number from '../views/admin/Number.vue'
import NumberTest from '../views/admin/NumberTest.vue'
import Server from '../views/admin/Server.vue'
import DigitalOcean from '../views/admin/DigitalOcean.vue'


import Page404 from '../views/Page404.vue'

import LogWhatsapp from '../views/log/Whatsapp.vue'
import LogWebhook from '../views/log/Webhook.vue'
import Error from '../views/log/Error.vue'

import Terminal from '../views/terminal/Terminal.vue'
import TerminalSistema from '../views/terminal/Sistema.vue'
import TerminalService from '../views/terminal/Service.vue'
import TerminalMessage from '../views/terminal/Message.vue'
import TerminalSsh from '../views/terminal/Ssh.vue'
import TerminalTest from '../views/terminal/Test.vue'
import TerminalWebhook from '../views/terminal/Webhook.vue'
import TerminalRotas from '../views/terminal/Rotas.vue'
import TerminalDatabase from '../views/terminal/Database.vue'
import TerminalWhatsapp from '../views/terminal/Whatsapp.vue'
import TerminalNodeWebhook from '../views/terminal/NodeWebhook.vue'
import TerminalNodeTerminal from '../views/terminal/NodeConsole.vue'
import TerminalNodeEvents from '../views/terminal/NodeEvents.vue'

const routes = [{
    path: '/',
    name: 'Dashborad',
    component: Dashborad,
},
{
    path: '/users',
    name: 'User',
    component: User,
},
{
    path: '/whatsapps',
    name: 'Whatsapp',
    component: Whatsapp,
},
{
    path: '/whatsapps-test',
    name: 'WhatsappTest',
    component: WhatsappTest,
},
{
    path: '/servers',
    name: 'Server',
    component: Server,
},
{
    path: '/processos',
    name: 'Process',
    component: Process,
},
{
    path: '/threads',
    name: 'Thread',
    component: Thread,
},
{
    path: '/github',
    name: 'Github',
    component: Github,
},
{
    path: '/api',
    name: 'Api',
    component: Api,
}, {
    path: '/webhooks',
    name: 'Webhook',
    component: Webhook,
},
{
    path: '/mensagens',
    name: 'Message',
    component: Message,
},
{
    path: '/mensagens-test',
    name: 'MessageTest',
    component: MessageTest,
},
{
    path: '/digitalocean',
    name: 'DigitalOcean',
    component: DigitalOcean,
},
{
    path: '/parametros',
    name: 'Parameter',
    component: Parameter,
},
{
    path: '/numeros',
    name: 'Number',
    component: Number,
},
{
    path: '/numeros-test',
    name: 'NumberTest',
    component: NumberTest,
},
{
    path: '/useragent',
    name: 'UserAgent',
    component: UserAgent,
},
{
    path: '/log/whatsapp',
    name: 'LogWhatsapp',
    component: LogWhatsapp,
},
{
    path: '/log/webhook',
    name: 'LogWebhook',
    component: LogWebhook,
},
{
    path: '/terminal',
    name: 'Terminal',
    component: Terminal,
},
{
    path: '/terminal/sistema',
    name: 'TerminalSistema',
    component: TerminalSistema,
},
{
    path: '/terminal/rotas',
    name: 'TerminalRotas',
    component: TerminalRotas,
},
{
    path: '/terminal/database',
    name: 'TerminalDatabase',
    component: TerminalDatabase,
},
{
    path: '/terminal/message',
    name: 'TerminalMessage',
    component: TerminalMessage,
},
{
    path: '/terminal/ssh',
    name: 'Terminalssh',
    component: TerminalSsh,
},
{
    path: '/terminal/webhook',
    name: 'TerminalWebhook',
    component: TerminalWebhook,
},
{
    path: '/terminal/whatsapp',
    name: 'TerminalWhatsapp',
    component: TerminalWhatsapp,
},
{
    path: '/terminal/service',
    name: 'TerminalService',
    component: TerminalService,
},
{
    path: '/terminal/test',
    name: 'TerminalTest',
    component: TerminalTest,
},
{
    path: '/terminal/node/event',
    name: 'TerminalNodeEvents',
    component: TerminalNodeEvents,
},
{
    path: '/terminal/node-webhook',
    name: 'TerminalNodeWebhook',
    component: TerminalNodeWebhook,
},
{
    path: '/terminal/node/terminal',
    name: 'TerminalNodeTerminal',
    component: TerminalNodeTerminal,
},
{
    path: '/erros',
    name: 'Error',
    component: Error,
}, {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
},
{
    path: '/:pathMatch(.*)*',
    name: 'Page404',
    component: Page404,
    meta: { public: true }
},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router