<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="true"
          @delete="$refs.datatable.update()"
          name="Número de telefone"
          names="Números de telefone"
          @create="$refs.datatable.update()"
          @onformcreate="openNew"
          @filter="$refs.datatable.applyFilters($event)"
          url="/whatsapp-number"
          :iniorderby="{ column: 'created', type: 'DESC' }"
          :btnfilter="false"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/whatsapp-number/query'"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:notificar_erro="data">
                <div class="text-center">
                  <i
                    class="menu-icon tf-icons bx bx-check text-success"
                    v-if="data.row.notificar_erro"
                  ></i
                  ><i class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Número <span class="text-danger">*</span></label
                >
                <input
                  type="tel"
                  class="form-control"
                  v-model="form.crud.form.number"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.number"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >User <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.user"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.user"
                ></Error>
              </div>
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label"
                  >Whatsapp <span class="text-danger">*</span></label
                >
                <SelectData
                  url="/whatsapp/query"
                  field="id"
                  label="name"
                  empty="Selecione"
                  :value="form.crud.form.whatsapp_id"
                  @value="form.crud.form.whatsapp_id = $event"
                  type="slot"
                  ><template v-slot:body="data">
                    <span
                      >{{
                        data.row.id +
                        " - " +
                        data.row.name +
                        " / " +
                        data.row.token
                      }}
                    </span>
                  </template>
                </SelectData>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.whatsapp_id"
                ></Error>
              </div>
              <hr />

              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import SelectData from "../../components/crud/input/SelectData.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    SelectData,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        name: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        level: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Whatsapp", value: "whatsapp_id" },
        { name: "Número", value: "number" },
        { name: "User", value: "user" },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    openNew(form) {
      form.whatsapp_id = null;
    },
    openEdit(form, data) {
      if (data.tags == null) {
        data.tags = [];
      }
      form.crud.edit(data);
    },
  },
};
</script>
