<template>
  <router-view />
</template>

<style>
</style>

<script>
export default {
  mounted() {
    this.isServiceWorkerRegistered().then((active) => {
      if (active) {
        this.registerServiceWorkerWithNotification("/service-worker.js")
          .then((registration) => {
            registration.active.postMessage({
              action: "token",
              token: this.getSession().auth.token,
            });
            registration.active.postMessage({
              action: "url",
              url: this.parseUrl("/system/web-notification"),
            });
            registration.active.postMessage({
              action: "interval",
              value: 10000,
            });
            registration.sync.register("fetchData");
          })
          .catch(() => {});
      }
    });
    setTimeout(() => {
      if (!this.$route.meta.public && !this.checkSession()) {
        debugger;
        this.$router.push("/login");
      }
    }, 100);
  },
};
</script>
