export default [{
    title: "Uso basico de mensagem",
    data: [
        {
            title: "Gerando o QR code",
            // method: "get",
            value: `<script src="{{origin}}/whatsapp-client.js"><\/script>`,
            type: 'markup',
            example: [
                {
                    title: "Implementação no HTML",
                    value: `<img id="imgqr" alt="" style="width: 300px; height: 300px;">
<button onclick="removeSession(event)">Remover sessão</button>
<script>
    // Cria um cliente
    const whatsapp = new Whatsapp('sadsadsad', document.getElementById('imgqr'), {
        test: true, // Usa os Whatsapp da lista teste 
        qrtimeout: 60000 * 5, // Tempo em ms para o evento qrtimeout quando falha em gerar qrcode
        event: {
            qr(data) {
                // Evento quando um qr code é gerado
                // data.code codigo do qrcode
                // data.img imgem em base64 do qrcode
            },
            ready(data) {
                // Esse evento acontece toda vez que tentar gerar um novo qr code caso tenha sido lido anteriormente
            },
            auth(data) {
                // Evento quando o qr code for lido a primeira vez
            },
            error(data) {
                // Evento quando um erro ao tentar gerar um qrcode acontecer
            },
            disconnect(data) {
                // Evento quando um erro ao tentar gerar um qrcode acontecer
            },
            qrtimeout(time) {
                // Evento quando o qrtimeout atingiu o limite
            },
            open(){
                // Evento quando a conexão é estabelecida
            },
            close() {
                // Evento quando a conexão for cancelada
            }
        },
        qr: {
            background: '#fff', // Cor do fundo
            backgroundAlpha: 0, // Transparência da cor de fundo valores entre 0 a 1
            level: 'M', // Nivel de detalhe do QRCode pode ser L, M, O, H
            foreground: '#000000', // Cor das linhas,
            foregroundAlpha: 1, // Transparência da cor das linhas valores entre 0 a 1
        },
        whatsapp: {
            group: 'Grupo a' // A qual grupo esse whatsapp pertence, null para todos ou um nome do mesmo grupo dos webhooks
            name: 'Whatsapp', // Nome default do whatsapp
            webhook: true, // Se irá emitir webhooks
            webhook_all_messages: true, // Se irá emitir webhook de mensagens que não foram enviadas pela api
        }
    });
    function removeSession(event) {

        whatsapp.disconnect();

        event.target.disabled = true;

        // Temporizador para reabilitar o botão após 10 segundos
        setTimeout(() => {
            event.target.disabled = false;
        }, 10000); // 10 segundos em milissegundos
    }
</script>`,

                    type: 'markup'
                },
            ],
        },
        {
            title: "Enviar mensagem/editar mensagens",
            method: "POST",
            type: "url",
            value: "{{origin}}/api/whatsapp/message",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            param: {
                body: {
                    required: true,
                    type: 'String',
                    description: 'Corpo da mensagem'
                },
                number: {
                    required: true,
                    type: 'String',
                    description: 'Número para ser enviado'
                },
                type: {
                    required: true,
                    type: 'String',
                    description: "Tipo da mensagem, deve ser 'text', 'image' ou 'link'"
                },
                expire: {
                    required: false,
                    type: 'Number',
                    description: 'Tempo em milissegundos para que a mensagem expire na fila de envio, por padrão é 24h'
                },
                mimetype: {
                    required: false,
                    type: 'String',
                    description: "Obrigatório apenas quando for envio de mensagem do type 'image' ou 'link'"
                },
                caption: {
                    required: false,
                    type: 'String',
                    description: "Descrição que fica abaixo da imagem enviada, penas quando for envio de mensagem do type 'image' ou 'link'"
                },
                uuid: {
                    required: false,
                    type: 'String',
                    description: "Identificador único fornecido para garantir que a mensagem não seja repetida, é opcional"
                },
                priority: {
                    required: false,
                    type: 'String',
                    description: "Prioridade dessa mensagem na fila, deve ser 'MAX', 'NORMAL' ou 'MIN' o valor padrão é NORMAL"
                }
            },
            example: [
                {
                    title: 'Mensagem de texto simples',
                    value: `{
    "body": "Texto da mensagem",
    "number": "999999999"
}`,
                    type: 'json'
                },
                {
                    title: 'Mensagem de com imagem em Base64',
                    value: `{
    "body": "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEABsbGxscGx4hIR4qLSgtKj04MzM4PV1CR0JHQl2NWGdYWGdYjX2Xe3N7l33gsJycsOD/2c7Z//////////////8BGxsbGxwbHiEhHiotKC0qPTgzMzg9XUJHQkdCXY1YZ1hYZ1iNfZd7c3uXfeCwnJyw4P/Zztn////////////////CABEIAEAAQAMBIgACEQEDEQH/xAAvAAADAQEBAAAAAAAAAAAAAAADBAUBAgYBAAMBAQAAAAAAAAAAAAAAAAADBAIB/9oADAMBAAIQAxAAAAAqFiWHUq8qC/DAs6R4r8bW1lAEth57MyialOqQwtKzsC8xmqb57LHL0IIuAOJds9qfbZTAtmPSdomoSxlzowTLIq//xAAjEAACAgICAgIDAQAAAAAAAAABAgADBBESITFBEzIFIzNR/9oACAEBAAE/ALMkLtatsx9x0Yn9jdn1DgslTOG2deBBVZs7UiU5jGs1MNzLf9aLKmVd7HZjqQ0WgKw4617MaxPkbdfJuUxy7WMeOl4xgWFgExsTVibmdQot0GmHg8rQXHQmRjg3WFfU55dgIACiVY/AlidmYqf0aHSM5mGwsZnHhZc/PKJ/wxHH3U960YlZ52Gxhpo+tQAk9Rs+qmplTtpkWn4gx+zTFQUYgGxyYbljlGZouUY2Qx9w7Y6EZBRQza2ZatLOAnLsxlN16oB0stzmbS6Gh1LmDLsQCBYmlYS17+X1BSFqOf8AIKdb3MSqusPe5+50JZ+OLuTW4KkzJx2p4q0WrYnxzfuLap13omZd5ttFSTjWEWthsKI2OAC1b66mXaLGqTeyvmBQBLD6jtoRm3Eq42izce+8GwldknqPbkWgAbEqxwnbdtHbiJ5n/8QAHhEAAwACAQUAAAAAAAAAAAAAAAECEBFRAxIhMWH/2gAIAQIBAT8AFp+RNV6w7qiFqNCntnSJfJ053t5dEW5fwVwy7lC5P//EAB0RAAMAAgMBAQAAAAAAAAAAAAABAgMREiFBEFH/2gAIAQMBAT8AKTWkUnK0zwmZXZke72VXKk2Wl4+jJWtIem/ixl4+aKx2iIpvtaH+H//Z",
    "number": "999999999",
    "type": "image",
    "mimetype": "image/jpeg",
    "caption": "Descrição da imagem"
}`,
                    type: 'json'
                },
                {
                    title: 'Mensagem de com imagem via link',
                    value: `{
    "body": "https://imagem.com/linkdaimagem",
    "number": "999999999",
    "type": "link",
    "mimetype": "image/jpeg",
    "caption": "Descrição da imagem"
}`,
                    type: 'json'
                },
                {
                    title: 'Mensagem de texto em lista',
                    value: `[
    {
        "uuid": "10",
        "body": "Mensagem de teste primeira",
        "number": "999999999",
        "priority": "LOW"
    },
    {
        "body": "Mensagem de teste prioridade MAX ",
        "number": "999999999",
        "priority": "MAX",
        "expire": 10000
    },
    {
        "uuid": "5",
        "body": "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEABsbGxscGx4hIR4qLSgtKj04MzM4PV1CR0JHQl2NWGdYWGdYjX2Xe3N7l33gsJycsOD/2c7Z//////////////8BGxsbGxwbHiEhHiotKC0qPTgzMzg9XUJHQkdCXY1YZ1hYZ1iNfZd7c3uXfeCwnJyw4P/Zztn////////////////CABEIAEAAQAMBIgACEQEDEQH/xAAvAAADAQEBAAAAAAAAAAAAAAADBAUBAgYBAAMBAQAAAAAAAAAAAAAAAAADBAIB/9oADAMBAAIQAxAAAAAqFiWHUq8qC/DAs6R4r8bW1lAEth57MyialOqQwtKzsC8xmqb57LHL0IIuAOJds9qfbZTAtmPSdomoSxlzowTLIq//xAAjEAACAgICAgIDAQAAAAAAAAABAgADBBESITFBEzIFIzNR/9oACAEBAAE/ALMkLtatsx9x0Yn9jdn1DgslTOG2deBBVZs7UiU5jGs1MNzLf9aLKmVd7HZjqQ0WgKw4617MaxPkbdfJuUxy7WMeOl4xgWFgExsTVibmdQot0GmHg8rQXHQmRjg3WFfU55dgIACiVY/AlidmYqf0aHSM5mGwsZnHhZc/PKJ/wxHH3U960YlZ52Gxhpo+tQAk9Rs+qmplTtpkWn4gx+zTFQUYgGxyYbljlGZouUY2Qx9w7Y6EZBRQza2ZatLOAnLsxlN16oB0stzmbS6Gh1LmDLsQCBYmlYS17+X1BSFqOf8AIKdb3MSqusPe5+50JZ+OLuTW4KkzJx2p4q0WrYnxzfuLap13omZd5ttFSTjWEWthsKI2OAC1b66mXaLGqTeyvmBQBLD6jtoRm3Eq42izce+8GwldknqPbkWgAbEqxwnbdtHbiJ5n/8QAHhEAAwACAQUAAAAAAAAAAAAAAAECEBFRAxIhMWH/2gAIAQIBAT8AFp+RNV6w7qiFqNCntnSJfJ053t5dEW5fwVwy7lC5P//EAB0RAAMAAgMBAQAAAAAAAAAAAAABAgMREiFBEFH/2gAIAQMBAT8AKTWkUnK0zwmZXZke72VXKk2Wl4+jJWtIem/ixl4+aKx2iIpvtaH+H//Z",
        "number": "999999999",
        "type": "image",
        "mimetype": "image/jpeg",
        "caption": "Descrição da imagem"
    }
]`,
                    type: 'json'
                }
            ],
            response: {
                200: [{
                    description: "Resposta padrão mensagem simples quando foi editada ou status de envio",
                    type: 'json',
                    value: `{
    "whatsapp_id": 1,
    "status": "QUEUE",
    "priority": "NORMAL",
    "from": null,
    "to": "999999999",
    "notify_name": null,
    "body": "Texto da mensagem",
    "type": "text",
    "caption": null,
    "mimetype": null,
    "timestamp": null,
    "sent": null,
    "delivered": null,
    "notified": null,
    "read": null,
    "device_type": null,
    "whatsapp_message_id": null,
    "from_me": true,
    "ack": 0,
    "expire": null,
    "hash": 441711260,
    "uuid": "1",
    "id": 1,
    "created": "2024-03-11T15:52:05.718",
    "updated": "2024-03-11T15:52:08.396"
}`,
                }, {
                    description: "Resposta padrão para varias mensagens",
                    type: 'json',
                    value: `[
    {
        "http_status": 200,
        "data": {
        "whatsapp_id": 58,
        "status": "QUEUE",
        "priority": "LOW",
        "from": null,
        "to": "999999999",
        "notify_name": null,
        "body": "Mensagem de teste primeira",
        "type": "text",
        "caption": null,
        "mimetype": null,
        "timestamp": null,
        "sent": null,
        "delivered": null,
        "notified": null,
        "read": null,
        "device_type": null,
        "whatsapp_message_id": null,
        "from_me": true,
        "ack": 0,
        "expire": null,
        "hash": 441711260,
        "uuid": "10",
        "id": 10,
        "created": "2024-03-11T15:52:05.718",
        "updated": "2024-03-11T15:56:12.755"
        }
    },
    {
        "http_status": 201,
        "data": {
        "whatsapp_id": 58,
        "status": "QUEUE",
        "priority": "MAX",
        "from": null,
        "to": "999999999",
        "notify_name": null,
        "body": "Mensagem de teste prioridade MAX ",
        "type": "text",
        "caption": null,
        "mimetype": null,
        "timestamp": null,
        "sent": null,
        "delivered": null,
        "notified": null,
        "read": null,
        "device_type": null,
        "whatsapp_message_id": null,
        "from_me": true,
        "ack": 0,
        "expire": "2024-03-11T15:56:22.762",
        "hash": 794458764,
        "uuid": null,
        "id": 11,
        "created": "2024-03-11T15:56:12.762",
        "updated": "2024-03-11T15:56:12.762"
        }
    },
    {
        "http_status": 201,
        "data": {
        "whatsapp_id": 58,
        "status": "QUEUE",
        "priority": "NORMAL",
        "from": null,
        "to": "999999999",
        "notify_name": null,
        "body": "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEABsbGxscGx4hIR4qLSgtKj04MzM4PV1CR0JHQl2NWGdYWGdYjX2Xe3N7l33gsJycsOD/2c7Z//////////////8BGxsbGxwbHiEhHiotKC0qPTgzMzg9XUJHQkdCXY1YZ1hYZ1iNfZd7c3uXfeCwnJyw4P/Zztn////////////////CABEIAEAAQAMBIgACEQEDEQH/xAAvAAADAQEBAAAAAAAAAAAAAAADBAUBAgYBAAMBAQAAAAAAAAAAAAAAAAADBAIB/9oADAMBAAIQAxAAAAAqFiWHUq8qC/DAs6R4r8bW1lAEth57MyialOqQwtKzsC8xmqb57LHL0IIuAOJds9qfbZTAtmPSdomoSxlzowTLIq//xAAjEAACAgICAgIDAQAAAAAAAAABAgADBBESITFBEzIFIzNR/9oACAEBAAE/ALMkLtatsx9x0Yn9jdn1DgslTOG2deBBVZs7UiU5jGs1MNzLf9aLKmVd7HZjqQ0WgKw4617MaxPkbdfJuUxy7WMeOl4xgWFgExsTVibmdQot0GmHg8rQXHQmRjg3WFfU55dgIACiVY/AlidmYqf0aHSM5mGwsZnHhZc/PKJ/wxHH3U960YlZ52Gxhpo+tQAk9Rs+qmplTtpkWn4gx+zTFQUYgGxyYbljlGZouUY2Qx9w7Y6EZBRQza2ZatLOAnLsxlN16oB0stzmbS6Gh1LmDLsQCBYmlYS17+X1BSFqOf8AIKdb3MSqusPe5+50JZ+OLuTW4KkzJx2p4q0WrYnxzfuLap13omZd5ttFSTjWEWthsKI2OAC1b66mXaLGqTeyvmBQBLD6jtoRm3Eq42izce+8GwldknqPbkWgAbEqxwnbdtHbiJ5n/8QAHhEAAwACAQUAAAAAAAAAAAAAAAECEBFRAxIhMWH/2gAIAQIBAT8AFp+RNV6w7qiFqNCntnSJfJ053t5dEW5fwVwy7lC5P//EAB0RAAMAAgMBAQAAAAAAAAAAAAABAgMREiFBEFH/2gAIAQMBAT8AKTWkUnK0zwmZXZke72VXKk2Wl4+jJWtIem/ixl4+aKx2iIpvtaH+H//Z",
        "type": "image",
        "caption": "Descrição da imagem",
        "mimetype": "image/jpeg",
        "timestamp": null,
        "sent": null,
        "delivered": null,
        "notified": null,
        "read": null,
        "device_type": null,
        "whatsapp_message_id": null,
        "from_me": true,
        "ack": 0,
        "expire": null,
        "hash": 1380741502,
        "uuid": "5",
        "id": 12,
        "created": "2024-03-11T15:56:12.774",
        "updated": "2024-03-11T15:56:12.774"
        }
    }
]`
                }],
                201: [{
                    description: "Resposta padrão mensagem simples quando foi criada",
                    type: 'json',
                    value: `{
    "whatsapp_id": 1,
    "status": "QUEUE",
    "priority": "NORMAL",
    "from": null,
    "to": "999999999",
    "notify_name": null,
    "body": "Texto da mensagem",
    "type": "text",
    "caption": null,
    "mimetype": null,
    "timestamp": null,
    "sent": null,
    "delivered": null,
    "notified": null,
    "read": null,
    "device_type": null,
    "whatsapp_message_id": null,
    "from_me": true,
    "ack": 0,
    "expire": null,
    "hash": 441711260,
    "uuid": "1",
    "id": 1,
    "created": "2024-03-11T15:52:05.718",
    "updated": "2024-03-11T15:52:08.396"
}`
                },],
                400: [{
                    description: "Json inválido",
                    value: `
{
    "error": "Invalid json",
    "cause": " End of input at line 2 column 2 path $.1"
}`,
                    type: 'json'
                },
                {
                    description: "Erro nos campos",
                    value: `
{
    "body": [
        {
          "message": "Required",
          "code": 1
        }
      ],
      "number": [
        {
          "message": "Required",
          "code": 1
        }
      ]
}`,
                    type: 'json'
                }],
                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                403: [{
                    description: "Sem corpo",
                    value: `
{
    "error": "No body content"
}`,
                    type: 'json'
                },
                ],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
    ],
}, {
    title: "Chat de mensagem",
    data: [
        {
            title: 'Obter todos chats de mensagem',
            method: "GET",
            type: "url",
            value: "{{origin}}/api/whatsapp/chats",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            response: {
                200: [{
                    description: "Chats obtidos",
                    value: `[
    {
        "chat_id": "9999999999@c.us",
        "name": "Chat 1",
        "is_group": false,
        "is_read_only": false,
        "unread_count": 0,
        "timestamp": 1710128778,
        "archived": false,
        "pinned": true,
        "mute_expiration": 0,
        "last_message": {
            "ack": 3,
            "from": "9999999999",
            "to": "99999999999",
            "body": "Chat message",
            "type": "chat",
            "device_type": "android",
            "whatsapp_message_id": "526759EDEC882B8DCB3AASE9C4D82D2B1",
            "from_me": true,
            "timestamp": 1710128778000
        }
    },
    {
        "chat_id": "9999999999@c.us",
        "name": "Chat 2",
        "is_group": false,
        "is_read_only": false,
        "unread_count": 0,
        "timestamp": 1710128778,
        "archived": false,
        "pinned": true,
        "mute_expiration": 0,
        "last_message": {
            "ack": 3,
            "from": "9999999999",
            "to": "99999999999",
            "body": "Chat message",
            "type": "chat",
            "device_type": "android",
            "whatsapp_message_id": "526759EDEC882B8DCB3AASE9C4D82D2B1",
            "from_me": true,
            "timestamp": 1710128778000
        }
    }
]`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                403: [{
                    description: "Whatsapp ainda não está online",
                    value: `
{
    "error": "Whatsapp is not online yet"
}`,
                    type: 'json'
                },
                {
                    description: "Whatsapp ainda não está conectado",
                    value: `
{
    "error": "Whatsapp is not connect"
}`,
                    type: 'json'
                },
                ],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Obter chat pelo número de telefone',
            method: "GET",
            type: "url",
            value: "{{origin}}/api/whatsapp/chat/{numero}",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            param: {
                number: {
                    required: true,
                    type: 'String',
                    description: 'Número do chat a ser buscado'
                },
            },
            response: {
                200: [{
                    description: "Chat existente",
                    value: `
{
    "chat_id": "9999999999@c.us",
    "name": "Chat 1",
    "is_group": false,
    "is_read_only": false,
    "unread_count": 0,
    "timestamp": 1710128778,
    "archived": false,
    "pinned": true,
    "mute_expiration": 0,
    "last_message": {
        "ack": 3,
        "from": "9999999999",
        "to": "99999999999",
        "body": "Chat message",
        "type": "chat",
        "device_type": "android",
        "whatsapp_message_id": "526759EDEC882B8DCB3AASE9C4D82D2B1",
        "from_me": true,
        "timestamp": 1710128778000
    }
}`,
                    type: 'json'
                }, {
                    description: 'Chat ainda não conversado',
                    value: `{
    "chat_id": "999999999@c.us",
    "name": "+55 99 9999-9999",
    "is_group": false,
    "is_read_only": false,
    "unread_count": 0,
    "timestamp": 0,
    "archived": false,
    "pinned": false,
    "mute_expiration": 0,
    "last_message": null
} `,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                403: [{
                    description: "Whatsapp ainda não está online",
                    value: `
{
    "error": "Whatsapp is not online yet"
}`,
                    type: 'json'
                },
                {
                    description: "Whatsapp ainda não está conectado",
                    value: `
{
    "error": "Whatsapp is not connect"
}`,
                    type: 'json'
                },
                ],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Status digitando no chat por 25 segundos',
            method: "POST",
            type: "url",
            value: "{{origin}}/api/whatsapp/chat/typing",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            param: {
                number: {
                    required: true,
                    type: 'String',
                    description: 'Número do chat a ficar digitando'
                },
            },
            example: [
                {
                    title: 'Marcando chat como digitando',
                    value: `{
    "number": "999999999"
}`,
                    type: 'json'
                },

            ],
            response: {
                200: [{
                    description: "Enviado com sucesso",
                    value: `{
    "chat_id": "9999999999@c.us",
    "name": "Chat name",
    "is_group": false,
    "is_read_only": false,
    "unread_count": 0,
    "timestamp": 1710128778,
    "archived": false,
    "pinned": true,
    "mute_expiration": 0,
    "last_message": {
        "ack": 3,
        "from": "9999999999",
        "to": "99999999999",
        "body": "Chat message",
        "type": "chat",
        "device_type": "android",
        "whatsapp_message_id": "526759EDEC882B8DCB3AASE9C4D82D2B1",
        "from_me": true,
        "timestamp": 1710128778000
    }
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                403: [{
                    description: "Whatsapp ainda não está online",
                    value: `
{
    "error": "Whatsapp is not online yet"
}`,
                    type: 'json'
                },
                {
                    description: "Whatsapp ainda não está conectado",
                    value: `
{
    "error": "Whatsapp is not connect"
}`,
                    type: 'json'
                },
                ],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Marcar chat como não lido',
            method: "POST",
            type: "url",
            value: "{{origin}}/api/whatsapp/chat/unread",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            param: {
                number: {
                    required: true,
                    type: 'String',
                    description: 'Número do chat a ficar marcado como não lido'
                },
            },
            example: [
                {
                    title: 'Marcando chat como não lido',
                    value: `{
    "number": "999999999"
}`,
                    type: 'json'
                },

            ],
            response: {
                200: [{
                    description: "Marcado com sucesso",
                    value: `{
    "chat_id": "9999999999@c.us",
    "name": "Chat name",
    "is_group": false,
    "is_read_only": false,
    "unread_count": -1,
    "timestamp": 1710128778,
    "archived": false,
    "pinned": true,
    "mute_expiration": 0,
    "last_message": {
        "ack": 3,
        "from": "9999999999",
        "to": "99999999999",
        "body": "Chat message",
        "type": "chat",
        "device_type": "android",
        "whatsapp_message_id": "526759EDEC882B8DCB3AASE9C4D82D2B1",
        "from_me": true,
        "timestamp": 1710128778000
    }
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                403: [{
                    description: "Whatsapp ainda não está online",
                    value: `
{
    "error": "Whatsapp is not online yet"
}`,
                    type: 'json'
                },
                {
                    description: "Whatsapp ainda não está conectado",
                    value: `
{
    "error": "Whatsapp is not connect"
}`,
                    type: 'json'
                },
                ],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Fixar chat',
            method: "POST",
            type: "url",
            value: "{{origin}}/api/whatsapp/chat/pinned",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            param: {
                number: {
                    required: true,
                    type: 'String',
                    description: 'Número do chat para fixar'
                },
                status: {
                    required: true,
                    type: 'String',
                    description: 'Valor true fixa e false desafixar'
                },
            },
            example: [
                {
                    title: 'Chat a ser fixado, status true fixa e false desafixar',
                    value: `{
    "number": "999999999",
    "status": true
}`,
                    type: 'json'
                },

            ],
            response: {
                200: [{
                    description: "Fixado com sucesso",
                    value: `{
    "chat_id": "9999999999@c.us",
    "name": "Chat name",
    "is_group": false,
    "is_read_only": false,
    "unread_count": -1,
    "timestamp": 1710128778,
    "archived": false,
    "pinned": true,
    "mute_expiration": 0,
    "last_message": {
        "ack": 3,
        "from": "9999999999",
        "to": "99999999999",
        "body": "Chat message",
        "type": "chat",
        "device_type": "android",
        "whatsapp_message_id": "526759EDEC882B8DCB3AASE9C4D82D2B1",
        "from_me": true,
        "timestamp": 1710128778000
    }
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                403: [{
                    description: "Whatsapp ainda não está online",
                    value: `
{
    "error": "Whatsapp is not online yet"
}`,
                    type: 'json'
                },
                {
                    description: "Whatsapp ainda não está conectado",
                    value: `
{
    "error": "Whatsapp is not connect"
}`,
                    type: 'json'
                },
                ],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Arquivar chat',
            method: "POST",
            type: "url",
            value: "{{origin}}/api/whatsapp/chat/archived",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            param: {
                number: {
                    required: true,
                    type: 'String',
                    description: 'Número do chat para fixar'
                },
                status: {
                    required: true,
                    type: 'String',
                    description: 'Valor true arquivar e false desarquivar'
                },
            },
            example: [
                {
                    title: 'Chat a ser arquivado, status true arquivar e false desarquivar',
                    value: `{
    "number": "999999999",
    "status": true
}`,
                    type: 'json'
                },

            ],
            response: {
                200: [{
                    description: "Arquivado com sucesso",
                    value: `{
    "chat_id": "9999999999@c.us",
    "name": "Chat name",
    "is_group": false,
    "is_read_only": false,
    "unread_count": -1,
    "timestamp": 1710128778,
    "archived": true,
    "pinned": true,
    "mute_expiration": 0,
    "last_message": {
        "ack": 3,
        "from": "9999999999",
        "to": "99999999999",
        "body": "Chat message",
        "type": "chat",
        "device_type": "android",
        "whatsapp_message_id": "526759EDEC882B8DCB3AASE9C4D82D2B1",
        "from_me": true,
        "timestamp": 1710128778000
    }
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                403: [{
                    description: "Whatsapp ainda não está online",
                    value: `
{
    "error": "Whatsapp is not online yet"
}`,
                    type: 'json'
                },
                {
                    description: "Whatsapp ainda não está conectado",
                    value: `
{
    "error": "Whatsapp is not connect"
}`,
                    type: 'json'
                },
                ],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        }
    ],
},
{
    title: "Modificar whatsapp",
    data: [
        {
            title: 'Inicializar um whatsapp',
            method: "PUT",
            type: "url",
            value: "{{origin}}/api/whatsapp/start",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            response: {
                200: [{
                    description: "Iniciado com sucesso",
                    value: `{
    "token": "token",
    "number": null,
    "status": "QUEUE",
    "status_connection": null,
    "name": null,
    "pushname": null,
    "image": null,
    "java_version": "1.1.06",
    "node_version": "1.23.1-alpha.5",
    "pid": null,
    "start": "2024-03-25T01:33:38.558",
    "auth": null,
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
    "platform": null,
    "enabled": true,
    "webhook_all_messages": false,
    "webhook": true,
    "qrcode": null,
    "group": "teste1 te",
    "session_id": null,
    "level": 1,
    "assistant_id": null,
    "id": 65,
    "created": "2024-03-20T13:58:45.955",
    "updated": "2024-03-25T01:59:25.536"
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Parar ou forçar parar um whatsapp',
            method: "DELETE",
            type: "url",
            value: "{{origin}}/api/whatsapp/stop",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            response: {
                200: [{
                    description: "Parado com sucesso",
                    value: `{
    "token": "token",
    "number": null,
    "status": "QUEUE",
    "status_connection": null,
    "name": null,
    "pushname": null,
    "image": null,
    "java_version": "1.1.06",
    "node_version": "1.23.1-alpha.5",
    "pid": null,
    "start": "2024-03-25T01:33:38.558",
    "auth": null,
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
    "platform": null,
    "enabled": true,
    "webhook_all_messages": false,
    "webhook": true,
    "qrcode": null,
    "group": "teste1 te",
    "session_id": null,
    "level": 1,
    "assistant_id": null,
    "id": 65,
    "created": "2024-03-20T13:58:45.955",
    "updated": "2024-03-25T01:59:25.536"
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Habilitar um whatsapp',
            method: "PUT",
            type: "url",
            value: "{{origin}}/api/whatsapp/enable",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            response: {
                200: [{
                    description: "Habilitado com sucesso",
                    value: `{
    "token": "token",
    "number": null,
    "status": "QUEUE",
    "status_connection": null,
    "name": null,
    "pushname": null,
    "image": null,
    "java_version": "1.1.06",
    "node_version": "1.23.1-alpha.5",
    "pid": null,
    "start": "2024-03-25T01:33:38.558",
    "auth": null,
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
    "platform": null,
    "enabled": true,
    "webhook_all_messages": false,
    "webhook": true,
    "qrcode": null,
    "group": "teste1 te",
    "session_id": null,
    "level": 1,
    "assistant_id": null,
    "id": 65,
    "created": "2024-03-20T13:58:45.955",
    "updated": "2024-03-25T01:59:25.536"
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Desabilitar um whatsapp',
            method: "PUT",
            type: "url",
            value: "{{origin}}/api/whatsapp/disable",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            response: {
                200: [{
                    description: "Desabilitado com sucesso",
                    value: `{
    "token": "token",
    "number": null,
    "status": "QUEUE",
    "status_connection": null,
    "name": null,
    "pushname": null,
    "image": null,
    "java_version": "1.1.06",
    "node_version": "1.23.1-alpha.5",
    "pid": null,
    "start": "2024-03-25T01:33:38.558",
    "auth": null,
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
    "platform": null,
    "enabled": true,
    "webhook_all_messages": false,
    "webhook": true,
    "qrcode": null,
    "group": "teste1 te",
    "session_id": null,
    "level": 1,
    "assistant_id": null,
    "id": 65,
    "created": "2024-03-20T13:58:45.955",
    "updated": "2024-03-25T01:59:25.536"
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        },
        {
            title: 'Deletar um whatsapp',
            method: "DELETE",
            type: "url",
            value: "{{origin}}/api/whatsapp/delete",
            header: {
                Authorization: {
                    value: "Bearer",
                    required: true,
                    type: "String",
                    description: "Api token privado",
                },
                "Content-Type": {
                    value: "application/json",
                },
            },
            response: {
                200: [{
                    description: "Deletado com sucesso",
                    value: `{
    "token": "token",
    "number": null,
    "status": "QUEUE",
    "status_connection": null,
    "name": null,
    "pushname": null,
    "image": null,
    "java_version": "1.1.06",
    "node_version": "1.23.1-alpha.5",
    "pid": null,
    "start": "2024-03-25T01:33:38.558",
    "auth": null,
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
    "platform": null,
    "enabled": true,
    "webhook_all_messages": false,
    "webhook": true,
    "qrcode": null,
    "group": "teste1 te",
    "session_id": null,
    "level": 1,
    "assistant_id": null,
    "id": 65,
    "created": "2024-03-20T13:58:45.955",
    "updated": "2024-03-25T01:59:25.536"
}`,
                    type: 'json'
                }],

                401: [{
                    description: "Erro na autenticação do token",
                    value: `
{
    "error": "Not authorized"
}`,
                    type: 'json'
                }],
                500: [{
                    description: "Erro no servidor",
                    value: `
{
    "error": "Internal error"
}`,
                    type: 'json'
                },
                ]
            },
        }
    ],
}]