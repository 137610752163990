<template>
  <div class="row">
    <div class="col-12 order-3 order-md-2" v-for="(c, ci) in config" :key="ci">
      <div class="card p-3 mb-3">
        <h4 class="text-center m-0">{{ c.title }}</h4>
        <div v-for="(d, di) in c.data" :key="di">
          <hr />
          <h5 class="card-header m-0 pb-3 pt-1 px-2">{{ d.title }}</h5>
          <div class="block-code">
            <div class="code">
              <div class="d-flex">
                <span :class="'method ' + d.method" v-if="d.method">{{
                  d.method.toUpperCase()
                }}</span>
                <div v-html="toScript(d)"></div>
                <i
                  class="tf-icons bx bx-copy text-white copy-url"
                  style="cursor: pointer"
                  @click="copyTextToClipboard(toScript(d, true))"
                ></i>
              </div>
            </div>
            <div v-if="d.header">
              <h4 class="payload-title">Header</h4>
              <div class="code">
                <div v-for="(p, pi) in d.header" :key="pi">
                  <span>
                    {{ pi }}:
                    <span v-if="p.value" class="value-code">{{ p.value }}</span>
                    <span class="type-code">{{ p.type }}</span>
                    <span class="required-code" v-if="p.required">*</span>
                    <span class="example-code" v-if="p.description"
                      >// {{ p.description }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
            <div v-if="d.param">
              <h4 class="payload-title">Parâmetros</h4>
              <div class="code">
                <div v-for="(p, pi) in d.param" :key="pi">
                  <span>
                    {{ pi }}: <span class="type-code">{{ p.type }}</span>
                    <span class="required-code" v-if="p.required">*</span>
                    <span class="example-code" v-if="p.description"
                      >// {{ p.description }}</span
                    >
                  </span>
                </div>
              </div>
            </div>

            <div v-if="d.example">
              <h4 class="payload-title">Exemplos de envio</h4>
              <div>
                <div
                  v-for="(p, pi) in d.example"
                  :key="pi"
                  class="code example-code-block"
                >
                  <h5 v-if="p.title" class="p-2 m-0 tex-center text-white">
                    {{ p.title }}
                  </h5>
                  <div class="copy-icon">
                    <i
                      class="tf-icons bx bx-copy text-white copy-url"
                      style="cursor: pointer"
                      @click="copyTextToClipboard(toScript(p, true))"
                    ></i>
                  </div>
                  <div class="code-json w-100">
                    <div v-html="toScript(p)"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="d.response">
              <h4 class="payload-title">Resposta</h4>
              <ul class="nav nav-tabs">
                <li class="nav-item" v-for="(t, ti) in d.response" :key="ti">
                  <button
                    type="button"
                    :class="'nav-link code ' + getActiveTab(ti, d.response, di)"
                    @click="active[di] = ti"
                  >
                    {{ ti }}
                  </button>
                </li>
              </ul>
              <div
                :class="'code tab ' + getActiveTab(ti, d.response, di)"
                v-for="(t, ti) in d.response"
                :key="ti"
              >
                <div v-for="(r, ri) in t" :key="ri">
                  <div>
                    <span class="example-code">// {{ r.description }}</span>
                  </div>

                  <div v-if="r.value" class="code-json">
                    <div class="copy-icon">
                      <i
                        class="tf-icons bx bx-copy text-white copy-url"
                        style="cursor: pointer"
                        @click="copyTextToClipboard(toScript(r, true))"
                      ></i>
                    </div>
                    <div v-html="toScript(r)"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["config"],
  data: {
    active: {},
  },
  methods: {
    toScript(r, copy = false) {
      if (r == null) {
        return;
      }
      var value = r.value;
      if (value != null) {
        value = value.replaceAll("{{origin}}", window.location.origin);
      }
      if (r.type != null && !copy) {
        if (r.type != "url")
          return Prism.highlight(value, Prism.languages[r.type], r.type);
      }
      return value;
    },
    getOrigin(r, v) {
      var value;
      if (r && r.origin != null) {
        if (!r.origin) {
          value = v;
        } else {
          value = window.location.origin + v;
        }
      } else {
        value = window.location.origin + v;
      }

      return value;
    },
    getActiveTab(v, r, ci) {
      if (v == this.active[ci]) {
        return "active";
      }
      if (r[this.active[ci]]) {
        return "";
      } else {
        var keys = Object.keys(r);
        if (keys[0] == v) {
          return "active";
        }
      }
      return "";
    },
  },
};
</script>
<style>
.example-code-block {
  vertical-align: middle;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}
.code-json {
  text-align: justify;
  white-space: pre;
  background-color: #272727;
  border-radius: 3px;
  padding: 1rem;
  position: relative;
  max-height: 500px;
  overflow: auto;
}
.code {
  background-color: #303030;
  color: #fff;
  /* font-family: Courier, monospace; */
  padding: 10px;
  border-radius: 0.2rem;
}

.code .method {
  background-color: #2b86ee;
  padding: 5px;
  border-radius: 3px;
  margin-right: 10px;
}

.method.put {
  background-color: #b743ce;
}

.method.post {
  background-color: #13c44e;
}

.code div {
  margin-top: 5px;
  margin-bottom: 5px;
}
.copy-icon {
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
}
.block-code {
  padding: 10px;
}

.value-code {
  margin-right: 10px;
}

.type-code {
  color: #59f0d7;
  margin-right: 5px;
}

.required-code {
  color: #ee8e34;
  margin-right: 5px;
}

.example-code {
  color: #a5a5a5;
}
.copy-url {
  margin-left: auto;
  margin-right: 5px;
}

.header-title,
.payload-title {
  margin-top: 15px;
  margin-bottom: 10px;
  /* background-color: #7481f1; */
  color: #2166e7;
  padding: 5px;
  border-bottom: 1px solid #2166e7;
  font-size: 18px;
}

.code .d-flex {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}
br {
  margin-bottom: 5px;
}
.code.tab {
  border-radius: 0 0.2rem 0.2rem 0.2rem;
  display: none;
}
.code.tab.active {
  display: block;
}
.nav-link.code {
  background-color: #6e6e6e !important;
  color: #fff !important;
}
.nav-link.code.active {
  background-color: #2d2d2d !important;
  color: #fff !important;
}
</style>