<template>
  <div class="h-100">
    <div class="datatable-body">
      <div class="table-responsive datatable-table" ref="div">
        <table
          class="table table-striped table-bordered"
          style="width: 100%"
          v-if="!load"
        >
          <thead>
            <tr>
              <th
                v-for="(f, i) in fields"
                :key="i"
                :style="f.style"
                :title="f.title"
              >
                <div
                  :class="
                    f.orderby == null || f.orderby == true
                      ? 'orderby-header'
                      : 'no-orderby-header'
                  "
                >
                  <span>{{ f.name }}</span>
                  <button
                    class="btn btn-white p-0 btn-orderby"
                    @click="
                      orderby.type = 'ASC';
                      orderby.column = f.value;
                    "
                    v-if="
                      (f.orderby == null || f.orderby == true) &&
                      orderby.type == 'DESC'
                    "
                  >
                    <i class="bx bx-chevron-up"></i>
                  </button>
                  <button
                    class="btn btn-white p-0 btn-orderby"
                    @click="
                      orderby.type = 'DESC';
                      orderby.column = f.value;
                    "
                    v-if="
                      (f.orderby == null || f.orderby == true) &&
                      orderby.type == 'ASC'
                    "
                  >
                    <i class="bx bx-chevron-down"></i>
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v, i) in values" :key="i">
              <td v-for="(f, fi) in fields" :key="fi">
                <div v-if="f.type == 'action'" class="d-flex">
                  <div class="m-auto d-flex">
                    <slot name="_action" :value="v[f.value]" :row="v"></slot>
                    <button
                      class="btn btn-white px-1 py-0"
                      @click="$emit('edit', v)"
                    >
                      <i class="bx bx-edit-alt text-primary"></i>
                    </button>

                    <button
                      class="btn btn-white px-1 py-0"
                      @click="$emit('delete', v.id)"
                    >
                      <i class="bx bx-trash text-danger"></i>
                    </button>
                  </div>
                </div>

                <div v-if="f.type == 'delete'" class="text-center">
                  <button
                    class="btn btn-white px-1 py-0"
                    @click="$emit('delete', v.id)"
                  >
                    <i class="bx bx-trash text-danger"></i>
                  </button>
                </div>
                <div v-if="f.type == 'edit'" class="text-center">
                  <button
                    class="btn btn-white px-1 py-0"
                    @click="$emit('edit', v)"
                  >
                    <i class="bx bx-edit-alt text-primary"></i>
                  </button>
                </div>
                <div
                  v-if="f.type == null"
                  v-html="f.format ? f.format(v[f.value], v) : v[f.value]"
                ></div>
                <slot
                  v-if="f.type == 'slot'"
                  :name="f.value"
                  :value="v[f.value]"
                  :row="v"
                ></slot>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="h-100">
          <div v-if="error" class="h-100 d-flex">
            <div class="m-auto">
              <div class="mb-3">
                <span> Falha ao buscar </span>
              </div>
              <button class="btn btn-primary" @click="update">
                Recarregar
              </button>
            </div>
          </div>
          <div v-else class="h-100 d-flex">
            <div class="m-auto text-center">
              <div class="mb-3">
                <span>Buscando dados</span>
              </div>

              <div
                class="spinner-border spinner-border-lg text-primary"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center w-100 mt-5" v-if="values.length == 0 && !load">
          <h5>Nenhum dado encontrado</h5>
        </div>
      </div>
      <div class="mt-3 nav-foot">
        <div class="row m-0">
          <div class="col-md-6 col-12">
            <nav>
              <ul class="pagination">
                <li class="page-item">
                  <button class="page-link" @click="page--" v-show="page > 1">
                    <i
                      class="menu-icon tf-icons bx bx-chevron-left m-0 p-0"
                    ></i>
                  </button>
                </li>
                <li
                  :class="'page-item' + (p == page ? ' active' : '')"
                  v-for="(p, i) in getPages()"
                  :key="i"
                >
                  <button
                    class="page-link btn-nav-dt"
                    @click="page == p ? update() : (page = p)"
                  >
                    {{ p }}
                  </button>
                </li>
                <li class="page-item">
                  <button
                    class="page-link"
                    @click="page++"
                    v-show="page < maxPage"
                  >
                    <i
                      class="menu-icon tf-icons bx bx-chevron-right m-0 p-0"
                    ></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-md-6 text-end d-none d-md-inline-block">
            Mostrando {{ (page > 0 ? page - 1 : 0) * limit }} até
            {{ getPageInfo() }} de {{ max }} registros
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "url",
    "fields",
    "where",
    "autoreload",
    "wait",
    "height",
    "iniorderby",
  ],
  data() {
    return {
      run: null,
      values: [],
      limit: 10,
      max: 0,
      page: 1,
      maxPage: 0,
      load: true,
      error: false,
      onLoad: false,
      orderby: this.iniorderby
        ? this.iniorderby
        : {
            column: null,
            type: "ASC",
          },
    };
  },
  methods: {
    getPageInfo() {
      var a = (this.page > 0 ? this.page - 1 : 0) * this.limit + this.limit;
      if (a > this.max) {
        return this.max;
      }
      return a;
    },
    applyFilters(filters) {
      for (const key in this.where) {
        delete this.where[key].value;
      }
      for (const key in filters) {
        if (this.where && this.where[key]) {
          if (filters[key] != null && filters[key] != "") {
            if (this.where[key].replace) {
              this.where[key].value = this.where[key].replace.replace(
                "{value}",
                filters[key]
              );
            } else {
              this.where[key].value = filters[key];
            }
          } else {
            this.where[key].value = null;
          }
        }
      }
      this.update();
    },
    getPages(p = this.isMobile() ? 3 : 5) {
      if (this.maxPage == 0) {
        return [];
      }
      if (this.maxPage == 1) {
        return [1];
      }
      var pages = [];
      var m = Math.floor(p / 2);
      var min = this.page - m;
      var max = this.page + Math.ceil(p / 2);
      pages.push(1);
      if (max > this.maxPage) {
        min = this.maxPage - p;
      }
      // console.log(max);
      var i = 0;
      var pg;
      do {
        pg = min + i++;
        if (pg > 1) {
          pages.push(pg);
        }
      } while (pg < this.maxPage - 1 && pages.length < p + 1);
      pages.push(this.maxPage);

      return pages;
    },
    computeSize(min = 5) {
      var size = this.$refs.div.offsetHeight - 43;
      size = size / (this.height ? this.height : 50);
      if (size > min) {
        this.limit = Math.floor(size);
      } else {
        this.limit = min;
      }
    },
    getValues(load = true) {
      this.load = load;
      this.onLoad = true;
      this.error = false;
      var filter = {
        limit: this.limit,
        offset: (this.page > 0 ? this.page - 1 : 0) * this.limit,
        orderby: {},
        where: {},
      };
      var pageRe = this.page;
      if (this.where) {
        for (const key in this.where) {
          if (this.where[key].value != undefined) {
            filter.where[key] = this.where[key];
          }
        }
      }
      if (this.orderby.column != null) {
        filter.orderby[this.orderby.column] = this.orderby.type;
      }
      this.crudList(this.url, filter)
        .then((res) => {
          if (this.page != pageRe) {
            return;
          }
          this.load = false;
          this.values = res.data.itens;
          this.max = res.data.max;
        })
        .catch((e) => {
          this.error = true;
        })
        .finally((e) => {
          this.onLoad = false;
        });
    },
    update() {
      this.getValues();
    },
  },

  mounted() {
    if (this.wait) {
      setTimeout(() => {
        this.computeSize();
        this.getValues();
      }, this.wait);
    } else {
      this.computeSize();
      this.getValues();
    }
    if (this.autoreload > 0) {
      this.run = setInterval(() => {
        if (!this.onLoad) {
          this.getValues(false);
        }
      }, this.autoreload);
    }
  },
  watch: {
    max(v) {
      this.maxPage = Math.ceil(v / this.limit);
      if (this.page > this.maxPage) {
        this.page = this.maxPage;
      }
    },
    page(page) {
      this.update();
    },
    "orderby.type"() {
      this.update();
    },
    "orderby.column"() {
      this.update();
    },
  },
  beforeUnmount() {
    clearInterval(this.run);
  },
};
</script>

<style scoped>
.card-title-datatable {
  display: inline-block;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.btn-nav-dt {
  line-height: 20px;
}

.datatable-table {
  min-height: calc(100% - 70px);
  height: calc(100% - 70px);
  max-height: calc(100% - 70px);
  overflow-y: auto;
}
.nav-foot {
  overflow: auto;
}
.no-orderby-header {
  line-height: 26px;
}
.orderby-header {
  display: flex;
  vertical-align: bottom;
}
.orderby-header span {
  line-height: 24px;
}
.btn-orderby {
  margin-left: auto;
  margin-right: 0px;
}
.datatable-body {
  height: 100%;
}
</style>