<template>
  <div>
    <h4 v-if="!tracer" class="text-center">Nenhuma inforção encontrada</h4>
    <div v-else class="tracer">
      <div v-for="(t, i) in tracer" :key="i" class="line">
        {{ t.class }}(
        <a
          :href="
            'https://gitlab.com/in2dev/whatsapp/-/tree/master/codigo-fonte/src/' +
            t.class.replaceAll('.', '/') +
            '.java/?#L' +
            t.line
          "
          target="_blank"
          v-if="t.class.startsWith('whatsapp')"
          >{{ t.file }}: {{ t.line }}
        </a>
        <span v-else>{{ t.file }}: {{ t.line }}</span> ) - {{ t.method }}()
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["thread_id"],
  data() {
    return {
      tracer: null,
    };
  },
  methods: {
    getInfo(id = this.thread_id) {
      this.urlGet("/system/info/thread/" + id)
        .then((data) => {
          if (data.data != null) {
            this.tracer = data.data.reverse();
          } else {
            this.tracer = null;
          }
        })
        .catch(() => {
          this.tracer = null;
        });
    },
  },
};
</script>

<style scoped>
.tracer {
  text-align: justify;
  white-space: pre;
  background-color: #414040;
  border-radius: 3px;
  padding: 1rem;
  position: relative;
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.tracer .line {
  color: #fff;
}
</style>