<template>
  <div class="h-100">
    <Layout ref="layout" :full="false">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          name="Whatsapp"
          names="Whatsapps"
          @onformcreate="openNew"
          @filter="$refs.datatable.applyFilters($event)"
          url="/log-whatsapp"
          :btnfilter="false"
          :cancreate="true"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/log-whatsapp/query'"
              :iniorderby="{ column: 'created', type: 'DESC' }"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
            </Datatable>
          </template>
          <!-- <template v-slot:form="form"> </template> -->
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      where: {
        name: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        level: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Whatsapp", value: "whatsapp_id" },
        { name: "Status", value: "status" },

        {
          name: "Origem",
          value: "origin",
        },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        // {
        //   name: "Ação",
        //   type: "action",
        //   orderby: false,
        //   value: "status",
        //   style: { width: "100px", minWidth: "100px", textAlign: "center" },
        // },
      ],
    };
  },
  methods: {},
  watch: {},
};
</script>

<style>
.copy-token {
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  line-height: 20px;
}
</style>
