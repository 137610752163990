<template>
  <div class="modal fade" tabindex="-1" ref="modal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title text-white mb-2">Informações da Thread</h5>
          <button type="button" class="btn-close" @click="show(false)"></button>
        </div>
        <div class="modal-body">
          <ThreadStackTracer ref="info"></ThreadStackTracer>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ThreadStackTracer from "./ThreadStackTracer.vue";
export default {
  props: ["thread_id", "autoreload"],
  components: {
    ThreadStackTracer,
  },
  data() {
    return {
      id: this.thread_id,
      timer: null,
    };
  },
  methods: {
    hide() {
      this.show(false);
    },
    show(show = true, id = this.id) {
      this.id = id;
      if (show) {
        $(this.$refs.modal).modal({
          backdrop: "static",
          keyboard: false,
        });
        clearInterval(this.timer);
        this.$refs.info.getInfo(this.id);
        if (this.autoreload > 0) {
          this.timer = setInterval(() => {
            this.$refs.info.getInfo(this.id);
          }, this.autoreload);
        }
      } else {
        //clearInterval(this.timer);
        $(this.$refs.modal).modal("hide");
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>