<template>
  <div class="scroll-div">
    <div class="scroll-roll" ref="div" @wheel="scroll" @touchend="scroll">
      <div :class="pageclass">
        <slot name="body" v-for="(v, i) in values" :key="i" :value="v"></slot>
      </div>
    </div>
    <div v-if="load" class="h-100">
      <div v-if="error" class="h-100 d-flex">
        <div class="m-auto">
          <div class="mb-3">
            <span> Falha ao buscar </span>
          </div>
          <button class="btn btn-primary" @click="update">Recarregar</button>
        </div>
      </div>
      <div v-else-if="values.length == 0" class="h-100 d-flex">
        <div class="m-auto text-center">
          <div class="mb-3">
            <span>Buscando dados</span>
          </div>

          <div
            class="spinner-border spinner-border-lg text-primary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else class="h-100 d-flex">
        <div class="m-auto text-center">
          <div
            class="spinner-border spinner-border-lg text-primary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center w-100 mt-5" v-if="values.length == 0 && !load">
      <h5>Nenhum dado encontrado</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "url",
    "where",
    "autoreload",
    "wait",
    "height",
    "iniorderby",
    "pageclass",
    "perpage",
  ],
  data() {
    return {
      run: null,
      values: [],
      limit: this.perpage ? this.perpage : 8,
      max: 0,
      page: 0,
      maxPage: 0,
      load: true,
      error: false,
      onLoad: false,
      orderby: this.iniorderby
        ? this.iniorderby
        : {
            column: null,
            type: "ASC",
          },
    };
  },
  methods: {
    applyFilters(filters) {
      for (const key in this.where) {
        delete this.where[key].value;
      }
      for (const key in filters) {
        if (this.where && this.where[key]) {
          if (filters[key] != null && filters[key] != "") {
            if (this.where[key].replace) {
              this.where[key].value = this.where[key].replace.replace(
                "{value}",
                filters[key]
              );
            } else {
              this.where[key].value = filters[key];
            }
          } else {
            this.where[key].value = null;
          }
        }
      }
      this.update();
    },
    scroll(ev) {
      var element = this.$refs.div;
      var height = element.clientHeight;
      var scrollHeight = element.scrollHeight;
      var scrollTop = element.scrollTop;
      // Verificar se o scroll está no final
      if (
        scrollTop + height >= scrollHeight - 15 &&
        (ev.wheelDeltaY == null || ev.wheelDeltaY < 0)
      ) {
        this.getValues();
      }
      ev.stopPropagation();
    },
    getValues(load = true, update = false) {
      this.load = load;
      this.onLoad = true;
      this.error = false;
      var limit = this.limit;
      var offset = this.page * this.limit;
      if (update) {
        limit = this.values.length;
        offset = 0;
      }
      var filter = {
        limit,
        offset,
        orderby: {},
        where: {},
      };
      var pageRe = this.page;
      if (this.where) {
        for (const key in this.where) {
          if (this.where[key].value != undefined) {
            filter.where[key] = this.where[key];
          }
        }
      }
      if (this.orderby.column != null) {
        filter.orderby[this.orderby.column] = this.orderby.type;
      }
      this.crudList(this.url, filter)
        .then((res) => {
          if (this.page != pageRe) {
            return;
          }
          if (update) {
            for (let i = 0; i < res.data.itens.length; i++) {
              this.values[i] = res.data.itens[i];
            }
          } else {
            this.page++;
            this.load = false;
            this.values.push(...res.data.itens);
            this.max = res.data.max;
          }
        })
        .catch((e) => {
          this.error = true;
        })
        .finally((e) => {
          this.onLoad = false;
        });
    },
    update() {
      this.getValues();
    },
  },

  mounted() {
    if (this.wait) {
      setTimeout(() => {
        this.getValues();
      }, this.wait);
    } else {
      this.getValues();
    }
    if (this.autoreload > 0) {
      this.run = setInterval(() => {
        if (!this.onLoad) {
          this.getValues(false, true);
        }
      }, this.autoreload);
    }
  },
  watch: {
    max(v) {
      this.maxPage = Math.ceil(v / this.limit);
      if (this.page > this.maxPage) {
        this.page = this.maxPage;
      }
    },
    "orderby.type"() {
      this.update();
    },
    "orderby.column"() {
      this.update();
    },
  },
  beforeUnmount() {
    clearInterval(this.run);
  },
};
</script>

<style scoped>
.btn-nav-dt {
  line-height: 20px;
}

.datatable-table {
  min-height: calc(100% - 50px);
  height: calc(100% - 50px);
  max-height: calc(100% - 50px);
  overflow-y: auto;
}
.nav-foot {
  overflow: auto;
}
.no-orderby-header {
  line-height: 26px;
}
.orderby-header {
  display: flex;
  vertical-align: bottom;
}
.orderby-header span {
  line-height: 24px;
}
.btn-orderby {
  margin-left: auto;
  margin-right: 0px;
}
.datatable-body {
  height: 100%;
}
.scroll-roll {
  height: 100%;
  overflow-y: auto;
  max-height: 100%;
  position: absolute;
  width: 100%;
}
.scroll-div {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>