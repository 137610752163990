import axios from 'axios'
import env from '../../env.json'
const UAParser = require('ua-parser-js');
var SESSION = localStorage.getItem('session');
const API_URL = env.dev ? env.api_test_url : env.api_url;
Number.prototype.format = function (min = 0, max = 2) {
    return this.toLocaleString(
        undefined, { minimumFractionDigits: min, maximumFractionDigits: max }
    );
}

var HEADERS = {

};
var SESSION_TOKEN = localStorage.getItem('session_token');

if (SESSION != null) {
    SESSION = JSON.parse(SESSION);
    try {
        HEADERS['Authorization'] = 'Bearer ' + SESSION.auth.token;
    } catch (ex) {
        SESSION = null;
        localStorage.removeItem('session');
    }
}


function randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
if (SESSION_TOKEN == null) {
    SESSION_TOKEN = randomString(255);
    localStorage.setItem('session_token', SESSION_TOKEN);
}
if (SESSION == null) {
    HEADERS['Authorization'] = 'Basic ' + SESSION_TOKEN;
}


var GLOBAL = {
    connection: null,
    events: {},
    sessionTimeout: null,
};


export default {
    data() {
        return {
            version: env.version,
            appName: env.app_name,
            appFullName: env.app_full_name,
            apiUrl: API_URL,

        }
    },
    methods: {
        parseUrl(url, origin = false) {
            if (url.startsWith('http') || url.startsWith('ws')) {
                return url;
            }
            if (origin) {
                return (env.dev ? '' : window.location.origin) + API_URL + url;
            }
            return API_URL + url;
        },
        getCaptcha(data, succes, error) {
            return this.urlPost('/captcha/image', data).then((e) => {
                if (succes) {
                    succes(e);
                }
            }).catch((e) => {
                if (error) {
                    error(e);
                }
            });
        },
        randomString(s) {
            return randomString(s)
        },
        setCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        },
        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        removeCookie(name) {
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        downloadBase64File(base64Data, fileName, contentType = 'application/octet-stream') {
            const linkSource = `data:${contentType};base64,${base64Data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        globalEvent(type, data) {
            if (GLOBAL.events && GLOBAL.events[type]) {
                for (const func of GLOBAL.events[type]) {
                    try {
                        func(data);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },
        addEvent(type, func) {
            if (GLOBAL.events[type] == null) {
                GLOBAL.events[type] = [];
            }
            GLOBAL.events[type].push(func);
        },
        removeEvent(type, func) {
            if (GLOBAL.events[type]) {
                GLOBAL.events[type] = GLOBAL.events[type].filter(fu => fu != func);
            }
        },
        registerServiceWorkerWithNotification(file) {
            return new Promise((suc, fail) => {
                this.permissionNotification().then(() => {
                    this.registerServiceWorker(file).then((data) => {
                        suc(data);
                    }).catch(() => {
                        fail()
                    })
                }).catch((error) => {
                    fail(error);
                })
            });

        },
        registerServiceWorker(file) {
            return new Promise((suc, fail) => {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.register(file)
                        .then(function (registration) {
                            suc(registration);
                        })
                        .catch(function (error) {
                            fail(error);
                        });
                }
            });
        },
        isServiceWorkerRegistered() {
            if ('serviceWorker' in navigator) {
                return navigator.serviceWorker.getRegistration()
                    .then(function (registration) {
                        return !!registration;
                    })
                    .catch(function (error) {
                        console.error('Erro ao verificar o registro do Service Worker:', error);
                        return false;
                    });
            } else {
                return Promise.resolve(false);
            }
        },
        removeServiceWorker() {
            if ('serviceWorker' in navigator) {
                return navigator.serviceWorker.getRegistration()
                    .then(function (registration) {
                        if (registration) {
                            return registration.unregister()
                                .then(function (isUnregistered) {
                                    console.log('Service worker ' + isUnregistered);
                                    return isUnregistered;
                                })
                                .catch(function (error) {
                                    console.error('Erro ao desregistrar o Service Worker:', error);
                                    return false;
                                });
                        } else {
                            console.log('Não há Service Worker registrado, portanto, consideramos que ele foi removido.');
                            // Não há Service Worker registrado, portanto, consideramos que ele foi removido.
                            return true;
                        }
                    })
                    .catch(function (error) {
                        console.error('Erro ao verificar o registro do Service Worker:', error);
                        return false;
                    });
            } else {
                return Promise.resolve(true); // O navegador não suporta Service Workers, portanto, consideramos que ele foi removido.
            }
        },
        permissionNotification() {
            return new Promise((suc, fail) => {
                if (Notification.permission === 'default') {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === 'granted') {
                            suc();
                        } else {
                            fail();
                        }
                    }).catch(() => {
                        fail()
                    });
                }
                if (Notification.permission === 'granted') {
                    suc();
                } else {
                    fail();
                }
            });
        },
        hasPermissionNotification() {
            if ("Notification" in window) {
                if (Notification.permission === "granted") {
                    return true;
                }
            }
            return false;
        },
        canNotification() {
            if ("Notification" in window) {
                if (Notification.permission === "granted") {
                    return true;
                } else if (Notification.permission !== "denied") {
                    return true;
                }
            }
            return false;
        },
        createWebsocket(url, event, reconnect = 10000) {
            var urlParse = this.parseUrl(url, true);
            urlParse = urlParse.replace('http', 'ws');
            const socket = new WebSocket(urlParse);
            socket.addEventListener('open', (ev) => {
                if (event && event.open) {
                    event.open(ev);
                }
            });
            socket.addEventListener('close', (ev) => {
                if (reconnect && (event == null || (event.close == null || event.close(ev)))) {
                    setTimeout(() => {
                        this.createWebsocket(url, event, reconnect);
                    }, reconnect);
                }
            });
            socket.addEventListener('message', (ev) => {
                if (ev.data == 'PING') {
                    socket.send('PONG');
                    return;
                }
                if (event && event.message) {
                    event.message(ev);
                }
            });
            socket.addEventListener('error', (ev) => {
                socket.close();
                if (event && event.error) {
                    event.error(ev);
                }
            });
            return socket;
        },
        sessionWebsocket() {
            if (SESSION == null) {
                return;
            }
            if (GLOBAL.connection) {
                GLOBAL.connection.close();
            }
            var main = this;
            const socket = this.createWebsocket('/session/connection?token=' + SESSION.auth.token, {
                message(event) {
                    if (event.data == 'LOGOUT') {
                        main.logout(false);
                        main.globalEvent('logout');
                        return;
                    }
                    if (event.data == 'UPDATE_USER') {
                        main.updateUser()
                        return;
                    }
                },
                close() {
                    GLOBAL.connection = null;
                    return true;
                },
                open() {
                    main.updateUser();
                }
            });

            GLOBAL.connection = socket;
        },
        isMobile() {
            var isMobile = {
                Android: function () {
                    return navigator.userAgent.match(/Android/i);
                },
                BlackBerry: function () {
                    return navigator.userAgent.match(/BlackBerry/i);
                },
                iOS: function () {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
                },
                Opera: function () {
                    return navigator.userAgent.match(/Opera Mini/i);
                },
                Windows: function () {
                    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
                },
                any: function () {
                    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
                }
            }
            return isMobile.any() != null;
        },
        getError(erros, code) {
            if (erros == null) {
                return null;
            }
            for (const erro of erros) {
                if (erro.code == code) {
                    return erro;
                }
            }
            return null;
        },
        crudCreate(type, data) {
            return this.urlPost(type, data);
        },
        crudUpdate(type, data) {
            return this.urlPut(type, data);
        },
        crudDelete(type, id) {
            return this.urlDelete(type + '/' + id);
        },
        crudList(type, filter) {
            return this.urlPost(type, filter);
        },
        urlGet(url) {
            return axios.get(this.parseUrl(url), { headers: HEADERS });
        },
        urlPost(url, data) {
            return axios.post(this.parseUrl(url), data, { headers: HEADERS });
        },
        urlDelete(url) {
            return axios.delete(this.parseUrl(url), { headers: HEADERS });
        },
        urlPut(url, data) {
            return axios.put(this.parseUrl(url), data, { headers: HEADERS });
        },
        login(data, success, error) {
            var captcha = null;
            if (data.captcha) {
                captcha = data.captcha;
                delete data.captcha;
            }
            var url = '/auth/login';
            if (captcha) {
                url += '?captcha=' + captcha
            }
            this.urlPost(url, data).then((res) => {
                SESSION = res.data;
                HEADERS['Authorization'] = 'Bearer ' + SESSION.auth.token;
                localStorage.setItem('session', JSON.stringify(SESSION));
                // this.sessionWebsocket();
                this.setSessionTimeout();
                success(res.data);

            }).catch((e) => {
                error(e.response)
            });
        },
        getSession() {
            return SESSION;
        },
        setSessionTimeout() {
            if (!SESSION) {
                clearTimeout(GLOBAL.sessionTimeout);
                return;
            }

            if (!GLOBAL.sessionTimeout) {

                var now = new Date().getTime();
                var expire = new Date(SESSION.auth.expire).getTime();
                var expire = expire - now;

                if (expire <= 0) {
                    this.logout();
                }
                if (expire < (1000 * 60 * 60 * 24)) {
                    GLOBAL.sessionTimeout = setTimeout(() => {
                        this.logout(false);
                        this.checkSession();
                    }, expire);
                }

            }
        },
        updateUser(suc, err, fin) {
            this.urlGet('/session/user').then((e) => {
                SESSION.user = e.data;
                localStorage.setItem('session', JSON.stringify(SESSION));
                this.setSessionTimeout();
                this.globalEvent('update_user');
                if (suc) {
                    suc(e);
                }
            }).catch((e) => {
                if (err) {
                    suc(e);
                }
                if (e.response.status) {
                    this.logout();
                    this.$router.push('/login');
                }
            }).finally(() => {
                if (fin) {
                    fin();
                }
            });
        },
        getUser() {
            return SESSION != null && SESSION.user != null ? SESSION.user : null
        },
        userLevel(level) {
            var user = this.getUser();
            return user && user.level >= level;
        },
        logout() {
            HEADERS['Authorization'] = 'Basic ' + SESSION_TOKEN;
            SESSION = null;
            localStorage.removeItem('session');
            if (GLOBAL.connection) {
                GLOBAL.connection.close();
            }
        },
        checkSession() {
            if (SESSION != null) {
                this.setSessionTimeout();
                // this.updateUser();
                // if (GLOBAL.connection == null) {
                //     //  this.sessionWebsocket();
                // }
                return true;
            } else {
                if (!this.$route.meta.public) {
                    this.$router.push("/login");
                }
                return false;
            }
        },
        copyTextToClipboard(text) {
            navigator.clipboard.writeText(text).then(function () {
                console.log('Async: Copying to clipboard was successful!');
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        parseUserAgent(user) {
            let parser = new UAParser(user);
            return parser.getResult();
        },
        getRun(url, action, time) {
            var run = true;
            this.urlGet(url).then((data) => {
                action(data);
            }).catch(() => {

            }).finally(() => {
                run = false;
            })
            return setInterval(() => {
                if (!run) {
                    run = true;
                    this.urlGet(url).then((data) => {
                        action(data);
                    }).catch(() => {

                    }).finally(() => {
                        run = false;
                    })
                }
            }, time)
        }
    },
    created() {

    },
    unmounted() {

    }
}