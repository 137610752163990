<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <div>
          <div class="row">
            <div
              v-for="(c, i) in cards"
              @click="$router.push(c.link)"
              :key="i"
              class="col-lg-3 col-12 col-sm-6 col-md-4"
            >
              <div class="card mb-4 card-link">
                <div class="card-header">
                  <h5 class="card-title">{{ c.name }}</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4 text-center">
                      <i
                        :class="'card-icon menu-icon tf-icons bx bx-' + c.icon"
                      ></i>
                    </div>
                    <div class="col-8 text-center card-total px-0">
                      {{ c.total != null ? c.total.format(0) : "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "../../components/layout/Tema.vue";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      cards: [
        {
          name: "Sistema",
          link: "/terminal/sistema",
          value: "system",
          icon: "chip",
        },
        {
          name: "Serviços",
          value: "service",
          link: "/terminal/service",
          icon: "cog",
        },
        {
          name: "Banco de dados",
          link: "/terminal/database",
          value: "database",
          icon: "data",
        },
        {
          name: "Rotas",
          value: "routes",
          link: "/terminal/rotas",
          icon: "list-ul",
        },
        {
          name: "Node terminal",
          value: "node_terminal",
          link: "/terminal/node/terminal",
          icon: "filter-alt",
        },
        {
          name: "Node eventos",
          value: "node_event",
          link: "/terminal/node/event",
          icon: "code",
        },
        {
          name: "SSH",
          value: "ssh",
          link: "/terminal/ssh",
          icon: "filter-alt",
        },
      ],
    };
  },
  methods: {
    getTotals() {
      this.urlGet("/terminal/total")
        .then((e) => {
          for (const c of this.cards) {
            var value = e.data[c.value];
            if (value != null) {
              c.total = value;
            }
          }
        })
        .catch((e) => {});
    },
  },
  mounted() {
    this.getTotals();
  },
};
</script>

<style>
.card-icon {
  font-size: 50px;
}
.card-total {
  font-size: 30px;
  font-weight: bold;
}
.card-link {
  cursor: pointer;
}
</style>