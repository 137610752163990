<template>
  <div class="h-100">
    <div class="row m-0 h-100">
      <div class="col-12 col-md-3 login-card bg-primary">
        <div>
          <div class="px-3 mt-5 w-100 md-float-material form-material">
            <div class="card-block">
              <div class="mb-3">
                <div class="text-center">
                  <img src="/img/logo.png" alt="" height="200" />
                </div>
              </div>
              <h3 class="text-center my-5 text-white">{{ appFullName }}</h3>
              <div class="mb-4">
                <div class="input-group input-group-merge">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Login"
                    v-model="form.login"
                  />
                </div>
              </div>
              <div>
                <div class="mb-4 form-password-toggle">
                  <div class="input-group input-group-merge">
                    <input
                      :type="inpuType"
                      class="form-control"
                      placeholder="Senha"
                      v-model="form.password"
                      autocomplete="new-password"
                      @keyup.enter="doLogin"
                    />
                    <span
                      class="input-group-text cursor-pointer"
                      @click="
                        inpuType == 'password'
                          ? (inpuType = 'text')
                          : (inpuType = 'password')
                      "
                      ><i class="bx bx-hide" v-if="inpuType == 'password'"></i>
                      <i class="bx bx-show" v-else></i
                    ></span>
                  </div>
                  <div class="form-text text-danger px-1" v-show="erro">
                    {{ erro }}
                  </div>
                </div>
                <Captcha
                  class="mb-4"
                  ref="captcha"
                  method="POST"
                  path="/api/auth/login"
                  :hours="1"
                  @value="form.captcha = $event"
                >
                </Captcha>
                <div class="mb-3">
                  <button class="btn btn-success d-grid w-100" @click="doLogin">
                    Acessar
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left m-b-0 text-white">
                    Versão {{ version }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 px-0 h-100 d-none d-md-flex">
        <img src="img/fundo.png" alt="" class="w-100 h-100" />
      </div>
    </div>
  </div>
</template>

<script>
import Captcha from "../components/Captcha.vue";
export default {
  components: {
    Captcha,
  },
  data() {
    return {
      lembrar: false,
      inpuType: "password",
      erro: false,
      form: {
        login: "",
        senha: "",
        captcha: null,
      },
    };
  },
  watch: {},
  mounted() {
    this.$refs.captcha.update();
  },
  methods: {
    doLogin() {
      this.erro = false;
      this.login(
        this.form,
        (data) => {
          this.$router.push("/");
          // window.location = "/";
        },
        (e) => {
          this.$refs.captcha.update();
          if (e.status == 401) {
            this.erro = "Digite o código";
          } else {
            this.erro = "Senha incorreta";
          }
        }
      );
    },
  },
};
</script>

<style>
.login-card {
  background-color: #ccc;
}
</style>