<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="true"
          @delete="$refs.datatable.update()"
          name="Parâmetro"
          names="Parâmetros"
          @onformcreate="openNew"
          @create="$refs.datatable.update()"
          @filter="$refs.datatable.applyFilters($event)"
          url="/parameter"
          :cancreate="false"
          :btnfilter="false"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/parameter/query'"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:notificar_erro="data">
                <div class="text-center">
                  <i
                    class="menu-icon tf-icons bx bx-check text-success"
                    v-if="data.row.notificar_erro"
                  ></i
                  ><i class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Nome <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Tipo <span class="text-danger">*</span></label
                >
                <select
                  class="form-select"
                  v-model="form.crud.form.type"
                  disabled
                >
                  <option value="JSON">JSON</option>
                  <option value="STRING">STRING</option>
                  <option value="NUMBER">NUMBER</option>
                  <option value="BOOLEAN">BOOLEAN</option>
                </select>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.level"
                ></Error>
              </div>
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label"
                  >Valor <span class="text-danger">*</span></label
                >
                <textarea
                  class="form-control"
                  v-model="form.crud.form.value"
                  rows="4"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.value"
                ></Error>
              </div>

              <hr />

              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        name: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        level: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Nome", value: "name" },
        { name: "Tipo", value: "type" },
        {
          name: "Valor",
          value: "value",
          format: (v, d) => {
            if (v != null) {
              return (v + "").substring(0, 50) + (v.length > 50 ? "..." : "");
            } else {
              return "-";
            }
          },
        },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "edit",
          orderby: false,
          value: "status",
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    openNew(form) {
      form.tags = [];
    },
    openEdit(form, data) {
      if (data.tags == null) {
        data.tags = [];
      }
      form.crud.edit(data);
    },
  },
};
</script>
