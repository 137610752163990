<template>
  <div class="layout-wrapper layout-content-navbar h-100">
    <div :class="'layout-container' + (colapse ? ' layout-menu-fixed' : '')">
      <aside class="layout-menu menu-vertical menu bg-menu-theme">
        <div class="app-brand">
          <a href="/" class="app-brand-link">
            <span class="app-brand-logo">
              <img src="/img/logo.png" height="30" alt="" />
            </span>
            <span
              class="app-brand-text demo menu-text fw-bolder ms-2 text-white"
              >{{ appName }}</span
            >
          </a>

          <a
            href="javascript:void(0);"
            @click="colapse = false"
            v-if="colapse"
            style="display: block !important"
            class="layout-menu-toggle menu-link text-large ms-auto d-block"
          >
            <i class="bx bx-chevron-left bx-sm align-middle"></i>
          </a>
          <span class="version">v{{ version }}</span>
        </div>
        <div
          class="menu-inner-shadow"
          style="display: block"
          v-if="colapse"
        ></div>
        <div class="menu-inner-shadow" v-else></div>

        <ul
          class="menu-inner py-1"
          style="
            max-height: calc(100vh - 75px);
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <!-- Dashboard -->
          <li
            :class="
              (m.path ? 'menu-item' : 'menu-header') +
              (active(m.path) ? ' active' : '')
            "
            v-for="(m, i) in getMenu()"
            :key="i"
          >
            <a
              v-if="m.path"
              @click="
                $router.push(m.path);
                colapse = false;
              "
              class="menu-link"
              style="cursor: pointer"
            >
              <i :class="'menu-icon tf-icons bx bx-' + m.icon"></i>
              <div data-i18n="Analytics">{{ m.name }}</div>
            </a>
            <span class="menu-header-text" v-else>{{ m.name }}</span>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              style="cursor: pointer"
              @click="
                logout();
                $router.push('/login');
              "
              ><i class="menu-icon tf-icons bx bx-power-off"></i>
              <div data-i18n="Analytics">Sair</div></a
            >
          </li>
        </ul>
      </aside>
      <div
        class="layout-overlay layout-menu-toggle d-block"
        v-if="colapse"
      ></div>
      <div class="layout-page app-scroll">
        <!-- Navbar -->

        <nav class="d-flex d-xl-none layout-navbar align-items-center full">
          <div
            class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
          >
            <a
              class="nav-item nav-link px-0 me-xl-4 text-white"
              href="javascript:void(0)"
              @click="colapse = true"
            >
              <i class="bx bx-menu bx-sm"></i>
            </a>
          </div>
          <div
            class="nav-item align-items-center text-center"
            style="width: calc(100% - 70px)"
          >
            <img src="/img/logo.png" height="35" alt="" />
          </div>
          <span class="version">v{{ version }}</span>
        </nav>
        <div class="content-wrapper" v-if="!full">
          <!-- Content -->

          <div class="container-xxl flex-grow-1 container-p-y">
            <slot name="body"></slot>
          </div>
        </div>
        <div class="layout-full-body" v-else>
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["full"],
  data() {
    return {
      menuItem: window.location.pathname,
      menu: [
        { name: "Home", path: "/", icon: "chip" },
        { name: "Painel" },
        // { name: "Processos", path: "/processos", icon: "sitemap" },
        { name: "Threads", path: "/threads", icon: "pulse" },
        { name: "Terminal", path: "/terminal", icon: "terminal" },
        { name: "Digitalocean", path: "/digitalocean", icon: "desktop" },
        { name: "Testes", path: "/automatic-test", icon: "clipboard" },
        { name: "Github", path: "/github", icon: "bug-alt" },
        { name: "Cadastro" },
        { name: "Whatsapps", path: "/whatsapps", icon: "phone" },
        { name: "Usuários", path: "/users", icon: "user" },
        { name: "Webhooks", path: "/webhooks", icon: "link" },
        { name: "Mensagens", path: "/mensagens", icon: "message" },
        { name: "Números", path: "/numeros", icon: "grid" },
        { name: "Parâmetros", path: "/parametros", icon: "code-curly" },
        { name: "User agent", path: "/useragent", icon: "globe" },
        { name: "Servidores", path: "/servers", icon: "server" },
        { name: "Teste" },
        { name: "Whatsapps (teste)", path: "/whatsapps-test", icon: "phone" },
        { name: "Números (testes)", path: "/numeros-test", icon: "grid" },
        { name: "Mensagens (teste)", path: "/mensagens-test", icon: "message" },
        { name: "Logs" },
        { name: "Whatsapps", path: "/log/whatsapp", icon: "key" },
        { name: "Webhooks", path: "/log/webhook", icon: "link" },
        { name: "Erros", path: "/erros", icon: "bug" },
        { name: "Notificações", path: "/notificacao", icon: "envelope" },
        { name: "Api" },
        { name: "Documentação", path: "/api", icon: "code-alt" },
        { name: "Sistema" },
      ],
      colapse: false,
    };
  },
  methods: {
    getMenu() {
      return this.menu;
    },
    active(path) {
      if (path == null) {
        return false;
      }
      if (path == "/") {
        return path == this.menuItem;
      }
      // debugger;
      var r = this.menuItem + "/";
      return r.startsWith(path + "/");
    },
  },
};
</script>


<style>
.app-scroll {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.layout-menu-fixed .menu-vertical {
  animation: menu-expand ease-in-out 0.1s !important;
  transform: translate3d(0, 0, 0);
}
@keyframes menu-expand {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0%, 0%);
  }
}
.app-brand {
  background-color: #696cff;
  margin: 0px !important;
  height: 75px !important;
  position: relative;
}
.full {
  margin: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
  padding: 1rem;
  background-color: #696cff !important;
  color: #fff;
}
.menu-item.active {
  position: relative;
}
.version {
  color: #fff;
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
}
.layout-full-body {
  width: 100%;
  height: 100%;
}
@media (max-width: 1199.98px) {
  .app-brand {
    position: unset;
  }
  .layout-full-body {
    height: calc(100% - 62px);
  }
}
</style>