<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="true"
          @create="$refs.datatable.update()"
          @delete="$refs.datatable.update()"
          name="Usuário"
          names="Usuários"
          @onformcreate="openNew"
          @filter="$refs.datatable.applyFilters($event)"
          url="/user"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/user/query'"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:notificar_erro="data">
                <div class="text-center">
                  <span>
                    E
                    <i
                      class="menu-icon tf-icons bx bx-check text-success"
                      v-if="data.row.notify_error"
                    ></i
                    ><i
                      class="menu-icon tf-icons bx bx-x text-danger"
                      v-else
                    ></i>
                  </span>
                  <span>
                    NS
                    <i
                      class="menu-icon tf-icons bx bx-check text-success"
                      v-if="data.row.notify_newserver"
                    ></i
                    ><i
                      class="menu-icon tf-icons bx bx-x text-danger"
                      v-else
                    ></i>
                  </span>
                  <span>
                    NU
                    <i
                      class="menu-icon tf-icons bx bx-check text-success"
                      v-if="data.row.notify_newuser"
                    ></i
                    ><i
                      class="menu-icon tf-icons bx bx-x text-danger"
                      v-else
                    ></i>
                  </span>
                  <span>
                    NV
                    <i
                      class="menu-icon tf-icons bx bx-check text-success"
                      v-if="data.row.notify_newversion"
                    ></i
                    ><i
                      class="menu-icon tf-icons bx bx-x text-danger"
                      v-else
                    ></i>
                  </span>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Nome <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >E-mail <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  v-model="form.crud.form.email"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.email"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Login <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.login"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.login"
                ></Error>
              </div>

              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Senha <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.password"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.password"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Nível de acesso <span class="text-danger">*</span></label
                >
                <select class="form-select" v-model="form.crud.form.level">
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.level"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Notificação <span class="text-danger">*</span></label
                >
                <div class="row">
                  <div class="col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="notificar_erro"
                        v-model="form.crud.form.notify_error"
                      />
                      <label class="form-check-label" for="notificar_erro"
                        >Erros dos sistema</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="notify_newuser"
                        v-model="form.crud.form.notify_newuser"
                      />
                      <label class="form-check-label" for="notify_newuser"
                        >Novo usuário</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="notify_newserver"
                        v-model="form.crud.form.notify_newserver"
                      />
                      <label class="form-check-label" for="notify_newserver"
                        >Novo servidor</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="notify_newversion"
                        v-model="form.crud.form.notify_newversion"
                      />
                      <label class="form-check-label" for="notify_newversion"
                        >Nova versão lib</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        name: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        level: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Nome", value: "name" },
        { name: "Login", value: "login" },
        { name: "E-mail", value: "email" },
        {
          name: "Level",
          value: "level",
          style: { width: "100px" },
          format: (v, d) => {
            if (v == 10) {
              return "Admin";
            }
            if (v == 5) {
              return "Suporte";
            }
            if (v == 2) {
              return "Especial";
            }
            if (v == 0) {
              return "Normal";
            }
          },
        },
        {
          name: "Notificação",
          value: "notificar_erro",
          style: { width: "200px" },
          type: "slot",
        },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    openNew(form) {
      form.level = 0;
    },
    openEdit(form, data) {
      form.crud.edit(data);
    },
  },
};
</script>
