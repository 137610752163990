<template>
  <div class="h-100">
    <layout ref="layout">
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <div class="card px-3 pb-4">
              <h5 class="card-header">Erros da lib no github</h5>
              <div style="overflow: auto">
                <div class="table table-striped table-bordered">
                  <table class="table">
                    <thead class="bg-primary">
                      <tr>
                        <th class="text-white">#</th>
                        <th style="max-width: 500px" class="text-white">
                          Nome
                        </th>
                        <th class="text-white">Status</th>
                        <th class="text-white">Criado</th>
                      </tr>
                    </thead>

                    <tbody class="table-border-bottom-0">
                      <tr
                        v-for="(t, i) in body"
                        :key="i"
                        :style="{ backgroundColor: getColor(t) }"
                      >
                        <td>{{ i }}</td>
                        <td>{{ t.title }}</td>
                        <td>
                          <div class="d-flex">
                            <span
                              v-for="(l, li) in t.labels"
                              :key="li"
                              :style="{ backgroundColor: '#' + l.color }"
                              class="tag"
                              >{{ l.name }}</span
                            >
                          </div>
                        </td>
                        <td>{{ new Date(t.created_at).toLocaleString() }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "../components/layout/Tema.vue";
export default {
  components: {
    layout,
  },
  data() {
    return {
      body: null,
    };
  },
  methods: {
    getColor(v) {
      if (v.labels.length > 0) {
        return "#" + v.labels[0].color + "44";
      }
    },
  },
  mounted() {
    this.urlGet("/system/github")
      .then((data) => {
        this.body = data.data;
        console.log(data.data);
      })
      .catch((e) => {
        debugger;
      });
  },
  beforeUnmount() {},
};
</script>
<style scoped>
.tag {
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
}
</style>