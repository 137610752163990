<template>
  <div class="col-md-3 col-lg-3 col-xl-2 col-6 p-1">
    <div :class="'wrapper ' + data.status.toLowerCase()" :title="data.status">
      <div class="profile">
        <div class="img-profile">
          <span class="status">{{ data.status }}</span>
          <img
            @click="showQrCode(data.qrcode, $event)"
            class="thumbnail"
            v-if="data.image"
            :src="data.image"
          />
          <img
            @click="showQrCode(data.qrcode, $event)"
            class="thumbnail"
            v-else-if="data.qrcode"
            :src="generateQrCode(data.qrcode)"
          />
        </div>

        <h4 class="pushname">
          {{ data.pushname ? data.pushname : "-" }}
        </h4>
        <h5 class="name">
          {{ data.name ? data.name : "-" }}
        </h5>
        <p class="title">
          {{ data.token }}
          <i
            class="tf-icons bx bx-copy"
            style="cursor: pointer"
            @click="copyToken(data.token, $event)"
          ></i>
        </p>
        <p class="description"></p>
      </div>
      <div class="social-icons">
        <div class="icon" v-if="data.user_agent" :title="data.user_agent">
          <img :src="getDevice(data.user_agent)" alt="" class="profile-icon" />
        </div>

        <div class="icon" v-if="data.platform" :title="data.platform">
          <img
            :src="getTypeMobileDevice(data.platform)"
            alt=""
            class="profile-icon"
          />
        </div>

        <div class="icon" v-if="data.platform" :title="data.platform">
          <img
            :src="getWhatsappType(data.platform)"
            alt=""
            class="profile-icon"
          />
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_qr">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Escanear qr code</h5>
            <button
              type="button"
              class="btn-close"
              @click="showQrCode(null, $event)"
            ></button>
          </div>
          <div class="modal-body" v-if="qrcode">
            <QrCode :token="data.token" :test="test"></QrCode>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QrCode from "./QrCode.vue";
export default {
  components: {
    QrCode,
  },
  props: ["data", "test"],
  data() {
    return {
      qrcode: null,
    };
  },
  methods: {
    getWhatsappType(type) {
      if (type == "smbi" || type == "smba") {
        return "/img/wb.png";
      } else {
        return "/img/wn.png";
      }
    },
    getTypeMobileDevice(device) {
      if (device == "android" || device == "smba") {
        return "/img/devices/android.png";
      } else {
        return "/img/devices/ios.png";
      }
    },
    copyToken(token, event) {
      this.copyTextToClipboard(token);
      event.stopPropagation();
    },
    showQrCode(qrcode = null, event) {
      if (qrcode) {
        this.qrcode = qrcode;
        $(this.$refs.modal_qr).modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.qrcode = null;
        $(this.$refs.modal_qr).modal("hide");
      }
      event.stopPropagation();
    },
    generateQrCode(code) {
      var con = {
        value: code,
        size: 276,
        level: "L",
      };
      const qr = new QRious(con);
      return qr.toDataURL();
    },
    getDevice(data) {
      if (data == null) {
        return "/img/devices/default.png";
      }
      data = this.parseUserAgent(data);
      var name = data.browser.name;
      var device = data.device.model;
      var types = ["firefox", "chrome", "cromium", "opera", "safari"];
      if (name) {
        name = name.toLocaleLowerCase();
      }
      if (device) {
        device = device.toLocaleLowerCase();
      }
      if (device) {
        if (device == "iphone") {
          return "/img/devices/ios.png";
        } else {
          return "/img/devices/android.png";
        }
      }
      for (const t of types) {
        if (t == name) {
          return "/img/devices/" + name + ".png";
        }
      }
      return "/img/devices/default.png";
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-width: 120px;
  /* min-height: 540px; */
  background: #fdfeff;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
  height: 100%;
  cursor: pointer;
}
.wrapper .profile {
  position: relative;
}
.wrapper .profile .thumbnail {
  width: 80px;
  height: 80px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
}
.wrapper .profile .img-profile {
  height: 80px;
  position: relative;
}
.wrapper .profile .img-profile .status {
  position: absolute;
  font-size: 12px;
  color: #000000;
}
.wrapper .social-icons {
  display: flex;
  margin-top: 5px;
  justify-content: center;
}
.wrapper .profile .name {
  color: #2d354a;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
.wrapper .profile .pushname {
  color: #2d354a;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
.wrapper .profile .title {
  color: #7c8097;
  font-size: 0.75em;
  font-weight: 300;
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.7em;
  letter-spacing: 1.5px;
  margin-bottom: 0px;
}
.wrapper .profile .description {
  color: #080911;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 1.3em;
}
.profile-icon {
  height: 30px;
  width: 30px;
}
.social-icons .icon {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #e2e2e2 !important;
  padding: 5px;
}
.wrapper.online {
  background-color: #8ada9b;
}
.wrapper.error {
  background-color: #f77b7b;
}
.wrapper.qrcode {
  background-color: #d5d5d6;
}
.wrapper.reconnect {
  background-color: #e4e47f;
}
.wrapper.queue {
  background-color: #7ee5ec;
}
.wrapper.starting {
  background-color: #a4c6f3;
}
.wrapper.reboot {
  background-color: #a4c6f3;
}
</style>