<template>
  <div class="d-flex">
    <img ref="qr" style="width: 276px; height: 276px; margin: auto" />
  </div>
</template>
<script>
import Env from "../../env.json";
export default {
  props: ["token", "test"],
  data() {
    return {
      socket: null,
      socketOpen: true,
      status: "LOAD",
      img: { qr: null, load: null, fix: null, ready: null },
    };
  },
  methods: {
    getImage() {
      switch (this.status) {
        case "QR": {
          return this.img.qr.img;
        }
        case "FIX": {
          return this.img.fix;
        }
        case "READY": {
          return this.img.ready;
        }
        default: {
          return this.img.load ? this.img.load : false;
        }
      }
    },
    message(evt) {
      var json = JSON.parse(evt.data);
      switch (json.type) {
        case "QR": {
          this.status = "QR";
          this.img.qr = json.data;
          break;
        }
        case "READY": {
          this.status = "READY";
          break;
        }
        case "IMG_LOAD": {
          this.img.load = json.data;
          break;
        }
        case "IMG_MAINTENANCE": {
          this.img.fix = json.data;
          break;
        }
        case "IMG_READY": {
          this.img.ready = json.data;
          break;
        }
      }
    },
    open() {},
    close() {
      return this.socketOpen;
    },
  },
  mounted() {
    this.socket = new Whatsapp(this.token, this.$refs.qr, {
      origin: Env.dev
        ? Env.api_test_domain.replace("http", "ws")
        : window.location.origin.replace("http", "ws"),
      test: this.test,
      event: {},
    });
  },
  beforeUnmount() {
    this.socket.close();
  },
};
</script>