<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <div class="row">
          <div class="col-lg-8 mb-4 order-0">
            <div class="card">
              <div class="d-flex align-items-end row">
                <div class="col-sm-7">
                  <div class="card-body card-info">
                    <h5 class="card-title text-primary">
                      {{ appFullName }} - v{{ version }} /
                      <span
                        >v{{
                          osInfo && osInfo.lib_version
                            ? osInfo.lib_version.replace("^", "")
                            : "-"
                        }}
                      </span>
                    </h5>
                    <!-- <p class="mb-1">API para Clientes do whatsappweb.</p> -->
                    <div>
                      <div class="form-check form-switch my-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="accept"
                          id="aceitar"
                        /><label class="form-check-label" for="aceitar"
                          >Aceitar novas conexões de clientes</label
                        >
                      </div>
                      <div class="form-check form-switch my-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="autoupdate"
                          id="autoupdate"
                        /><label class="form-check-label" for="autoupdate"
                          >Atualizar automaticamente</label
                        >
                      </div>
                      <div class="row mt-3">
                        <div class="col-5">
                          <button
                            class="btn btn-sm btn-primary w-100"
                            @click="activeNotification"
                            v-if="!active"
                          >
                            <i class="menu-icon tf-icons bx bx-bell"></i>
                            Ativar notificações
                          </button>
                          <button
                            class="btn btn-sm btn-danger w-100"
                            @click="desativarNotificacoes"
                            v-else
                          >
                            <i class="menu-icon tf-icons bx bx-bell"></i>
                            Desativar notificações
                          </button>
                        </div>
                        <div class="col-5">
                          <button
                            class="btn btn-sm btn-primary w-100"
                            @click="changeVersion"
                          >
                            <i class="menu-icon tf-icons bx bx-list-ul"></i>
                            Modificar versão
                          </button>
                        </div>
                      </div>
                    </div>
                    <h4 v-if="osInfo.status == 'STOP'" class="mt-5">
                      Parando o sistema...
                    </h4>
                    <h4 v-if="osInfo.status == 'UPDATE'" class="mt-5">
                      Atualizando o sistema...
                    </h4>
                  </div>
                </div>
                <div class="col-sm-5 text-center text-sm-left">
                  <div class="card-body pb-0 px-0 px-md-4">
                    <img
                      src="/img/man-with-laptop-light.png"
                      height="140"
                      alt="View Badge User"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-6 mb-4">
                <div class="card">
                  <div class="card-body card-info">
                    <div
                      class="card-title d-flex align-items-start justify-content-between"
                    >
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-user text-primary"></i
                        ></span>
                      </div>
                    </div>
                    <span class="d-block mb-1">Clientes</span>
                    <h3 class="card-title text-nowrap mb-2">
                      Ativos
                      {{ osInfo && osInfo.user ? osInfo.user.online : "-" }}
                    </h3>
                    <small class="text-primary fw-semibold"
                      >Total
                      {{
                        osInfo && osInfo.user ? osInfo.user.total : "-"
                      }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-6 mb-4">
                <div class="card">
                  <div class="card-body card-info">
                    <div
                      class="card-title d-flex align-items-start justify-content-between"
                    >
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-data text-primary"></i
                        ></span>
                      </div>
                    </div>
                    <span class="d-block mb-1">Database</span>
                    <h3 class="card-title text-nowrap mb-2">
                      {{
                        osInfo && osInfo.database
                          ? toGb(osInfo.database.size)
                          : "-"
                      }}
                    </h3>
                    <small class="text-primary fw-semibold">{{
                      osInfo && osInfo.database ? osInfo.database.name : "-"
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8 mb-4">
            <div class="card pb-3">
              <div class="row row-bordered g-0 mb-2">
                <div class="col-md-8 b-2">
                  <h5 class="card-header m-0 me-2 pb-3">Processador</h5>
                  <div ref="cpuChart2" class="px-2"></div>
                </div>
                <div class="col-md-4">
                  <div
                    class="d-flex px-xxl-4 px-lg-2 p-4 gap-xxl-3 gap-lg-1 gap-3 justify-content-between"
                  >
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-time text-primary"></i
                        ></span>
                      </div>
                      <div class="d-flex flex-column">
                        <small
                          >Uptime
                          {{ new Date(start()).toLocaleString() }}</small
                        >
                        <h6 class="mb-0">{{ uptime() }}</h6>
                      </div>
                    </div>
                  </div>
                  <div ref="cpuChart" class="mt-1"></div>
                  <div class="text-center fw-semibold pt-3 mb-2">
                    Java {{ (osInfo.cpu.java_per * 100).toFixed(2) }}%
                  </div>
                  <div
                    class="d-flex px-xxl-4 px-lg-2 p-4 gap-xxl-3 gap-lg-1 gap-3 justify-content-between"
                  >
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-pulse text-primary"></i
                        ></span>
                      </div>
                      <div class="d-flex flex-column">
                        <small>SO {{ osInfo.arch }}</small>
                        <h6 class="mb-0">
                          {{ osInfo.os }}
                        </h6>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-chip text-primary"></i
                        ></span>
                      </div>
                      <div class="d-flex flex-column">
                        <small>Núcleos</small>
                        <h6 class="mb-0">{{ osInfo.cpu.cores }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/ Total Revenue -->
          <div class="col-12 col-md-8 col-lg-4">
            <div class="row">
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body card-info">
                    <div
                      class="card-title d-flex align-items-start justify-content-between"
                    >
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-cylinder text-primary"></i
                        ></span>
                      </div>
                    </div>
                    <span class="d-block mb-1"
                      >Swap {{ value(osInfo.memory.swap, "size") }}</span
                    >
                    <h3 class="card-title text-nowrap mb-2">
                      {{ subValue(osInfo.memory.swap, "size", "free") }}
                    </h3>
                    <small class="text-primary fw-semibold"
                      >Livre {{ value(osInfo.memory.swap, "free") }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body card-info">
                    <div
                      class="card-title d-flex align-items-start justify-content-between"
                    >
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-code text-primary"></i
                        ></span>
                      </div>
                    </div>
                    <span class="d-block mb-1"
                      >Java M {{ value(osInfo.memory.java, "max") }}</span
                    >
                    <h3 class="card-title text-nowrap mb-2">
                      {{ value(osInfo.memory.java, "used") }}
                    </h3>
                    <small class="text-primary fw-semibold"
                      >Livre
                      {{ subValue(osInfo.memory.java, "max", "used") }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body card-info">
                    <div
                      class="card-title d-flex align-items-start justify-content-between"
                    >
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-memory-card text-primary"></i
                        ></span>
                      </div>
                    </div>
                    <span class="d-block mb-1"
                      >RAM {{ value(osInfo.memory, "size") }}</span
                    >
                    <h3 class="card-title text-nowrap mb-2">
                      {{ subValue(osInfo.memory, "size", "free") }}
                    </h3>
                    <small class="text-primary fw-semibold"
                      >Livre {{ value(osInfo.memory, "free") }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body card-info">
                    <div
                      class="card-title d-flex align-items-start justify-content-between"
                    >
                      <div class="me-2">
                        <span class="badge bg-label-primary p-2"
                          ><i class="bx bx-hdd text-primary"></i
                        ></span>
                      </div>
                    </div>
                    <span class="d-block mb-1"
                      >Disco {{ value(osInfo.disk, "size") }}</span
                    >
                    <h3 class="card-title text-nowrap mb-2">
                      {{ subValue(osInfo.disk, "size", "free") }}
                    </h3>
                    <small class="text-primary fw-semibold"
                      >Livre {{ value(osInfo.disk, "free") }}</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 mb-4">
            <div class="card h-100">
              <div
                class="card-header d-flex align-items-center justify-content-between"
              >
                <h5 class="card-title m-0 me-2">HTTP Clientes</h5>
              </div>
              <div class="card-body">
                <div class="dash-proccess">
                  <div class="table-responsive text-nowrap">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Uso (ms)</th>
                          <th>%</th>
                          <th>Status</th>
                          <th>Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, i) in osInfo.http_clients" :key="i">
                          <td>
                            {{ v.id }}
                          </td>
                          <td>{{ v.name }}</td>
                          <td>{{ v.cpu_ms }}</td>
                          <td>{{ (v.cpu / osInfo.cpu.time).toFixed(2) }} %</td>
                          <td>{{ v.status }}</td>
                          <td>
                            <div class="text-center">
                              <i
                                class="tf-icons bx bx-info-circle text-info"
                                style="cursor: pointer"
                                :title="v.thread_id"
                                @click="
                                  $refs.thread_info.show(true, v.thread_id)
                                "
                              ></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 mb-4">
            <div class="card h-100">
              <div
                class="card-header d-flex align-items-center justify-content-between"
              >
                <h5 class="card-title m-0 me-2">WS Clientes</h5>
              </div>
              <div class="card-body">
                <div class="dash-proccess">
                  <div class="table-responsive text-nowrap">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Uso (ms)</th>
                          <th>%</th>
                          <th>Status</th>
                          <th>Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, i) in osInfo.websocket_clients" :key="i">
                          <td>
                            {{ v.id }}
                          </td>
                          <td>{{ v.name }}</td>
                          <td>{{ v.cpu_ms }}</td>
                          <td>{{ (v.cpu / osInfo.cpu.time).toFixed(2) }} %</td>
                          <td>{{ v.status }}</td>
                          <td>
                            <div class="text-center">
                              <i
                                class="tf-icons bx bx-info-circle text-info"
                                style="cursor: pointer"
                                :title="v.thread_id"
                                @click="
                                  $refs.thread_info.show(true, v.thread_id)
                                "
                              ></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Layout>
    <ThreadStackTracerModal
      ref="thread_info"
      :autoreload="1000"
    ></ThreadStackTracerModal>
  </div>
</template>
<script>
import ThreadStackTracerModal from "../components/debugger/ThreadStackTracerModal.vue";
import Layout from "../components/layout/Tema.vue";
export default {
  components: {
    Layout,
    ThreadStackTracerModal,
  },
  data() {
    return {
      active: false,
      run: null,
      accept: true,
      autoupdate: true,
      chart: {
        cpu1: null,
        cpu2: null,
      },
      info: {
        connection: "-",
        indicator: "-",
        chart: "-",
        table: "-",
        page: "-",
        panel: "-",
        filter: "-",
        user: "-",
      },
      osInfo: {
        memory: {},
        cpu: {},
      },
      values: [],
    };
  },
  methods: {
    changeVersion() {
      this.urlGet("/system/node-versions").then((data) => {
        debugger;
      });
    },
    desativarNotificacoes() {
      this.removeServiceWorker();
      this.active = false;
    },
    activeNotification() {
      this.registerServiceWorkerWithNotification("/service-worker.js")
        .then((registration) => {
          this.active = true;
          registration.active.postMessage({
            action: "token",
            token: this.getSession().auth.token,
          });
          registration.active.postMessage({
            action: "url",
            url: this.parseUrl("/system/web-notification"),
          });
          registration.active.postMessage({
            action: "interval",
            value: 10000,
          });
          registration.sync.register("fetchData");
        })
        .catch(() => {});
    },
    pararSistema() {
      this.urlDelete("/system/parar-sistema");
    },
    atualizarNode() {
      this.urlPost("/system/atualizar");
    },
    aceitar() {
      this.urlPut("/system/aceitar ", {
        ativo: this.accept,
      });
    },
    subValue(ob, pro1, pro2) {
      if (ob && ob[pro1] && ob[pro2]) {
        return this.toGb(ob[pro1] - ob[pro2]);
      }
      return "-";
    },
    value(ob, pro) {
      if (ob && ob[pro]) {
        return this.toGb(ob[pro]);
      }
      return "-";
    },
    start() {
      if (this.osInfo.uptime) {
        var now = new Date();
        now.setTime(now.getTime() - this.osInfo.uptime);
        return now;
      } else {
        return new Date().getTime();
      }
    },
    chart1(cpu) {
      const growthChartEl = cpu;
      var growthChartOptions = {
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        series: [0],
        labels: ["CPU"],
        chart: {
          height: 240,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            size: 150,
            offsetY: 10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "55%",
            },
            track: {
              background: "#fff",
              strokeWidth: "100%",
            },
            dataLabels: {
              name: {
                offsetY: 15,
                color: "#000",
                fontSize: "15px",
                fontWeight: "600",
                fontFamily: "Public Sans",
              },
              value: {
                offsetY: -25,
                color: "#000",
                fontSize: "22px",
                fontWeight: "500",
                fontFamily: "Public Sans",
              },
            },
          },
        },
        colors: ["#696cff"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.5,
            gradientToColors: ["#696cff"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0.6,
            stops: [30, 70, 100],
          },
        },
        stroke: {
          dashArray: 5,
        },
        grid: {
          padding: {
            top: -35,
            bottom: -10,
          },
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            filter: {
              type: "none",
            },
          },
        },
      };

      var growthChart = new ApexCharts(growthChartEl, growthChartOptions);
      growthChart.render();

      return growthChart;
    },
    chart2(g) {
      const totalRevenueChartEl = g;
      var totalRevenueChartOptions = {
        series: [
          {
            data: [],
          },
        ],
        chart: {
          height: 300,
          stacked: true,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "33%",
            borderRadius: 12,
            startingShape: "rounded",
            endingShape: "rounded",
          },
        },
        colors: ["#000"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 6,
          lineCap: "round",
          colors: ["#696cff"],
        },
        legend: {
          show: true,
          horizontalAlign: "left",
          position: "top",
          markers: {
            height: 8,
            width: 8,
            radius: 12,
            offsetX: -3,
          },
          labels: {
            colors: "#000",
          },
          itemMargin: {
            horizontal: 10,
          },
        },
        grid: {
          borderColor: "#000",
          padding: {
            top: 0,
            bottom: -8,
            left: 20,
            right: 20,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "13px",
              colors: "#000",
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "13px",
              colors: "#000",
            },
          },
          min: 0,
          max: 100,
        },
        responsive: [
          {
            breakpoint: 1700,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "32%",
                },
              },
            },
          },
          {
            breakpoint: 1580,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "35%",
                },
              },
            },
          },
          {
            breakpoint: 1440,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "42%",
                },
              },
            },
          },
          {
            breakpoint: 1300,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "48%",
                },
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "40%",
                },
              },
            },
          },
          {
            breakpoint: 1040,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 11,
                  columnWidth: "48%",
                },
              },
            },
          },
          {
            breakpoint: 991,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "30%",
                },
              },
            },
          },
          {
            breakpoint: 840,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "35%",
                },
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "28%",
                },
              },
            },
          },
          {
            breakpoint: 640,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "32%",
                },
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "37%",
                },
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "45%",
                },
              },
            },
          },
          {
            breakpoint: 420,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "52%",
                },
              },
            },
          },
          {
            breakpoint: 380,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: "60%",
                },
              },
            },
          },
        ],
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            filter: {
              type: "none",
            },
          },
        },
      };

      const totalRevenueChart = new ApexCharts(
        totalRevenueChartEl,
        totalRevenueChartOptions
      );
      totalRevenueChart.render();
      return totalRevenueChart;
    },
    uptime() {
      if (this.osInfo.uptime) {
        var tempo = this.osInfo.uptime;
        var dia = parseInt(tempo / 86400000);
        tempo = tempo % 86400000;
        var hora = parseInt(tempo / 3600000);
        tempo = tempo % 3600000;
        var minuto = parseInt(tempo / 60000);
        tempo = tempo % 60000;
        // debugger
        var segundo = parseInt(tempo / 1000);
        var dt = segundo;
        if (dia > 0) {
          return dia + "d " + hora + "h " + minuto + "m " + segundo + "s";
        } else {
          if (hora > 0) {
            return hora + "h " + minuto + "m " + segundo + "s";
          } else {
            return minuto + "m " + segundo + "s";
          }
        }
      } else {
        return "0s";
      }
    },
    getInfo() {
      this.run = this.getRun(
        "/system/info",
        (res) => {
          this.osInfo = res.data;
          this.accept = res.data.news;
          var cpu = (this.osInfo.cpu.per * 100).toFixed(2);
          this.chart.cpu1.updateSeries([cpu]);
          this.values.push(cpu);
          if (this.values.length > 60) {
            this.values.shift();
          }
          this.chart.cpu2.updateSeries([
            {
              data: this.values,
            },
          ]);
        },
        1000
      );
    },
  },
  mounted() {
    this.isServiceWorkerRegistered().then((active) => {
      if (active) {
        this.active = active;
      }
    });
    this.chart.cpu1 = this.chart1(this.$refs.cpuChart);
    this.chart.cpu2 = this.chart2(this.$refs.cpuChart2);
    this.getInfo();
  },
  beforeUnmount() {
    this.chart.cpu1.destroy();
    this.chart.cpu2.destroy();
    clearInterval(this.run);
  },
};
</script>

<style>
.card-info {
  min-height: 188px;
}
.dash-proccess {
  max-height: 350px;
  overflow-y: auto;
}
</style>