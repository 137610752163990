<template>
  <div class="h-100">
    <Layout ref="layout" :full="true">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          name="Whatsapp"
          names="Whatsapps"
          @onformcreate="openNew"
          @filter="$refs.datatable.applyFilters($event)"
          url="/whatsapp-test"
          ref="crud"
        >
          <template v-slot:list="form">
            <ScollDatatable
              pageclass="row m-0"
              ref="datatable"
              :url="'/whatsapp-test/query'"
              :perpage="19"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :where="where"
              :iniorderby="{ column: 'created', type: 'DESC' }"
              :autoreload="1000"
            >
              <template v-slot:body="data">
                <WhatsappProfile
                  :data="data.value"
                  :test="true"
                  @click="openEdit($refs.crud, data.value)"
                />
              </template>
            </ScollDatatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label"
                  >Nome <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label"
                  >Token <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.token"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.token"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label">User Agent</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.user_agent"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.user_agent"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label">Grupo</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.group"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.group"
                ></Error>
              </div>
              <div class="mb-3 col-12">
                <div class="row">
                  <div class="col-md-4 col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="enabled"
                        v-model="form.crud.form.enabled"
                      />
                      <label class="form-check-label" for="enabled"
                        >Habilitado</label
                      >
                    </div>
                  </div>

                  <div class="col-md-4 col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="webhook"
                        v-model="form.crud.form.webhook"
                      />
                      <label class="form-check-label" for="webhook"
                        >Enviar webhooks (Auth, Logout... e mensagens
                        enviadas)</label
                      >
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="webhook_all_messages"
                        v-model="form.crud.form.webhook_all_messages"
                      />
                      <label class="form-check-label" for="webhook_all_messages"
                        >Enviar webhooks de todas as mensagens (Não
                        enviadas)</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="mb-3 col-md-3 col-12">
                <label class="form-label">Status</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.status"
                  readonly
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.status"
                ></Error>
              </div>

              <div class="mb-3 col-md-3 col-12">
                <label class="form-label">Sessão id</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.session_id"
                  readonly
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.session_id"
                ></Error>
              </div>
              <div class="mb-3 col-md-3 col-12">
                <label class="form-label">Java versão</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.java_version"
                  readonly
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.java_version"
                ></Error>
              </div>
              <div class="mb-3 col-md-3 col-12">
                <label class="form-label">Node versão</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.node_version"
                  readonly
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.node_version"
                ></Error>
              </div>
              <div class="mb-3 col-md-3 col-12">
                <label class="form-label">PID</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.pid"
                  readonly
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.pid"
                ></Error>
              </div>
              <div class="mb-3 col-md-3 col-12">
                <label class="form-label">Início</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.start"
                  readonly
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.start"
                ></Error>
              </div>
              <div class="mb-3 col-12 col-md-3" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-md-3" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Token</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.token"
                />
              </div>
              <!-- <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div> -->
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import ScollDatatable from "../../components/crud/ScrollDatatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import QrCode from "../../components/QrCode.vue";
import WhatsappProfile from "../../components/WhatsappProfile.vue";
export default {
  components: {
    Crud,
    Layout,
    ScollDatatable,
    Error,
    QrCode,
    WhatsappProfile,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      showQrCodeToken: null,
      stopToken: null,
      where: {
        token: {
          operator: "LIKE",
          replace: "%{value}%",
        },
      },
    };
  },
  methods: {
    openNew(form) {
      form.autofilter = true;
    },
    openEdit(form, data) {
      form.edit(data);
    },
    startWpp(token) {
      this.urlPut("/wpp-test/start/" + token)
        .then((e) => {
          //debugger;
        })
        .catch((e) => {
          // debugger;
        });
    },
    stopWpp(token) {
      this.stopToken = null;
      this.urlDelete("/wpp-test/stop/" + token)
        .then((e) => {
          //debugger;
        })
        .catch((e) => {
          // debugger;
        });
    },
    logoutWpp(token) {
      this.stopToken = null;
      this.urlDelete("/wpp-test/logout/" + token)
        .then((e) => {
          //debugger;
        })
        .catch((e) => {
          // debugger;
        });
    },
  },
  watch: {
    showQrCodeToken(v) {
      if (v != null) {
        $(this.$refs.qrcode).modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        $(this.$refs.qrcode).modal("hide");
      }
    },
    stopToken(v) {
      if (v != null) {
        $(this.$refs.closemodal).modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        $(this.$refs.closemodal).modal("hide");
      }
    },
  },
};
</script>

<style>
</style>
