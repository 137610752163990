<template>
  <div class="h-100">
    <Layout ref="layout" :full="false">
      <template v-slot:body>
        <Api :config="config"></Api>
      </template>
    </Layout>
  </div>
</template>

<script>
import Api from "../components/Api.vue";
import Layout from "../components/layout/Tema.vue";
import routes from "./api/routes.js";
export default {
  components: {
    Layout,
    Api,
  },
  data() {
    return {
      config: routes,
    };
  },
};
</script>