import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import api from './mixin/api';
import painel from './painel';
const Vue = createApp(App);
Vue.use(router);
Vue.use(VueAxios, axios);
Vue.mixin(api);
Vue.mixin(painel);
Vue.mount('#app');