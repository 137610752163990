<template>
  <div class="h-100">
    <layout ref="layout" :full="true">
      <template v-slot:body>
        <div @scroll="scroll" class="terminal-scroll" ref="scroll">
          <div class="row">
            <div class="col-12">
              <div class="card h-100" style="overflow-x: auto">
                <div
                  class="card-header d-flex align-items-center justify-content-between"
                >
                  <h4 class="card-title m-0 me-2">Node console</h4>
                </div>
                <div class="card-body" v-if="info">
                  <div v-for="(log, i) in info" :key="i" class="m-0">
                    <div class="terminal-text-min">
                      <div style="margin-right: 20px">
                        {{ new Date(log.data).toLocaleTimeString() }}
                      </div>
                      <div class="terminal-value">
                        <p class="m-0">
                          {{ log.value }}
                        </p>
                      </div>
                      <div style="margin-left: auto">
                        <i
                          class="tf-icons bx bx-copy text-white copy-url"
                          style="cursor: pointer"
                          @click="copyTextToClipboard(log.value)"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <alert ref="alert"></alert>
  </div>
</template>

<script>
import layout from "../../components/layout/Tema.vue";
import stick from "../../components/Stick.vue";
import alert from "../../components/Alert.vue";
export default {
  components: {
    layout,
    stick,
    alert,
  },
  data() {
    return {
      info: [],
      id: 0,
      run: null,
      autoscroll: true,
      socket: null,
      socketOpen: true,
    };
  },
  methods: {
    scroll(e) {
      var a = e.target.scrollTop;
      var b = e.target.scrollHeight - e.target.clientHeight;
      if (a >= b - 10) {
        this.autoscroll = true;
      } else {
        this.autoscroll = false;
      }
    },
    moveScroll() {
      var el = this.$refs.scroll;
      el.scrollTop = el.scrollHeight;
    },
    add(value) {
      this.info.push(value);
      if (this.info.length > 1000) {
        this.info.shift();
      }
      if (this.autoscroll) {
        setTimeout(() => {
          this.moveScroll();
        }, 100);
      }
    },
    message(evt) {
      var json = JSON.parse(evt.data);
      if (json.type == "ALL") {
        for (const data of json.data) {
          this.info.push(data);
        }
        setTimeout(() => {
          this.moveScroll();
        }, 100);
      }
      if (json.type == "SINGLE") {
        this.add(json.data);
      }
    },
    open() {
      this.info = [];
    },
    close() {
      return this.socketOpen;
    },
  },
  mounted() {
    this.socket = this.createWebsocket("/terminal/node/terminal/connect", this);
  },
  beforeUnmount() {
    this.socketOpen = false;
    this.socket.close();
    // clearInterval(this.run);
  },
};
</script>
<style>
.terminal-text-min {
  text-align: justify;
  white-space: pre;
  background-color: #414040;
  border-radius: 3px;
  padding: 0.5rem;
  position: relative;
  max-height: 150px;
  overflow: auto;
  color: #fff;
  display: flex;
}
.terminal-value {
  width: calc(100% - 120px);
  max-width: calc(100% - 120px);
  overflow: auto;
}
.terminal-scroll {
  overflow: auto;
  max-height: 100%;
  padding: 15px;
}
</style>