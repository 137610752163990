<template>
  <div class="h-100 w-100" v-if="img">
    <div class="row">
      <div class="col-6">
        <img
          v-if="img"
          :src="'data:image/png;base64,' + img"
          alt=""
          class="captcha-img"
        />
      </div>
      <div class="col-6">
        <div class="d-flex h-100">
          <div class="input-group input-group-merge m-auto">
            <input
              type="text"
              class="form-control"
              placeholder="Digite o código"
              v-model="value"
            /><span
              class="input-group-text cursor-pointer"
              title="Gerar novo código"
              @click="update"
              ><i class="bx bx-revision"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["path", "method", "maxuse", "hours", "ms", "minutes", "seconds"],
  data() {
    return {
      img: null,
      value: null,
    };
  },
  methods: {
    update() {
      this.checkCaptcha();
    },
    checkCaptcha() {
      var data = {
        path: this.path,
        method: this.method,
      };
      if (this.ms) {
        data.ms = this.ms;
      }
      if (this.hours) {
        data.hours = this.hours;
      }
      if (this.minutes) {
        data.minutes = this.minutes;
      }
      if (this.seconds) {
        data.seconds = this.seconds;
      }
      this.getCaptcha(data, (e) => {
        this.value = null;
        if (e.status == 204) {
          this.img = null;
        } else {
          this.img = e.data;
        }
      });
    },
  },
  watch: {
    value(v) {
      this.$emit("value", v);
    },
  },
};
</script>

<style>
.captcha-img {
  width: 100%;
  border-radius: 0.25rem;
  height: 100%;
}
</style>