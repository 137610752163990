<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          name="Mensagem de teste"
          names="Mensagens de teste"
          :btnfilter="true"
          :cancreate="true"
          @onformcreate="openNew"
          @filter="$refs.datatable.applyFilters($event)"
          url="/message-test"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/message-test/query'"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :iniorderby="{ column: 'created', type: 'DESC' }"
              :where="where"
              :autoreload="1000"
            >
              <template v-slot:status="data">
                <div class="text-center status-icons" :title="data.row.status">
                  <i
                    class="menu-icon tf-icons bx bx-time text-success"
                    v-if="data.row.status == 'QUEUE'"
                  ></i>
                  <i
                    class="menu-icon tf-icons bx bx-check"
                    v-else-if="data.row.status == 'SENT'"
                  ></i>
                  <i
                    class="menu-icon tf-icons bx bx-check-double"
                    v-else-if="data.row.status == 'DELIVERED'"
                  ></i>
                  <i
                    class="menu-icon tf-icons bx bx-check-double text-primary"
                    v-else-if="data.row.status == 'READ'"
                  ></i>
                  <i
                    class="menu-icon tf-icons bx bx-message-square-x text-warning"
                    v-else-if="data.row.status == 'EXPIRED'"
                  ></i>
                  <i
                    class="menu-icon tf-icons bx bx-x text-danger"
                    v-else-if="data.row.status == 'CANCELED'"
                  ></i>
                  <i
                    class="menu-icon tf-icons bx bx-message-alt-error text-danger"
                    v-else
                  ></i>
                </div>
              </template>
              <template v-slot:force="data">
                <div class="text-center">
                  <i
                    class="menu-icon tf-icons bx bx-check text-success"
                    v-if="data.row.force"
                  ></i
                  ><i class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label"
                  >Corpo da mensgaem <span class="text-danger">*</span></label
                >
                <textarea
                  class="form-control"
                  rows="4"
                  v-model="form.crud.form.body"
                >
                </textarea>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Número de envio <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  v-model="form.crud.form.to"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.to"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Caption <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.caption"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.caption"
                ></Error>
              </div>

              <div class="mb-3 col-md-4 col-12">
                <label class="form-label">Mimetype</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.mimetype"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.mimetype"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label"
                  >Tipo de mensagem <span class="text-danger">*</span></label
                >
                <select class="form-select" v-model="form.crud.form.type">
                  <option value="text">Texto</option>
                  <option value="image">Imagem</option>
                  <option value="link">Link</option>
                  <option value="buttons">Buttons</option>
                  <option value="list">List</option>
                </select>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.type"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label"
                  >Prioridade <span class="text-danger">*</span></label
                >
                <select class="form-select" v-model="form.crud.form.priority">
                  <option value="MAX">MAX</option>
                  <option value="NORMAL">NORMAL</option>
                  <option value="MIN">MIN</option>
                </select>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.type"
                ></Error>
              </div>
              <hr />

              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Corpo</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.body"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Enviado para</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.filters.to"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Whatsapp id</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.filters.whatsapp_id"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Status</label>
                <select class="form-select" v-model="data.filters.status">
                  <option :value="undefined">Todos</option>
                  <option value="QUEUE">QUEUE (File)</option>
                  <option value="SENT">SENT (Enviado)</option>
                  <option value="DELIVERED">DELIVERED (Entregue)</option>
                  <option value="READ">READ (Lido)</option>
                  <option value="EXPIRED">EXPIRED (Expirada)</option>
                  <option value="CANCELED">CANCELED (Cancelado)</option>
                  <option value="ERROR">ERROR (Erro)</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Prioridade</label>
                <select class="form-select" v-model="data.filters.priority">
                  <option :value="undefined">Todos</option>
                  <option value="MAX">MAX (Maxima)</option>
                  <option value="NORMAL">NORMAL (Normal)</option>
                  <option value="MIN">MIN (Minima)</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Tipo</label>
                <select class="form-select" v-model="data.filters.type">
                  <option :value="undefined">Todos</option>
                  <option value="text">text</option>
                  <option value="image">image</option>
                  <option value="link">link</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        body: {
          operator: "ILIKE",
          replace: "%{value}%",
        },
        status: {
          operator: "=",
        },
        whatsapp_id: {
          operator: "=",
        },
        type: {
          operator: "=",
        },
        type: {
          operator: "=",
        },
        priority: {
          operator: "=",
        },
        to: {
          operator: "LIKE",
          replace: "%{value}%",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "WPPID", value: "whatsapp_id" },
        // { name: "Remetente", value: "sender" },
        {
          name: "Corpo",
          value: "body",
          format: (v, d) => {
            if (v != null) {
              return v.substring(0, 50) + (v.length > 50 ? "..." : "");
            } else {
              return "-";
            }
          },
        },
        { name: "Tipo", value: "type" },
        { name: "Prio", value: "priority" },
        { name: "Para", value: "to" },
        {
          name: "Status",
          value: "status",
          style: {},
          type: "slot",
        },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    openNew(form) {
      form.type = "text";
      form.priority = "NORMAL";
    },
    openEdit(form, data) {
      form.crud.edit(data);
    },
  },
};
</script>

<style scoped>
.status-icons i {
  font-size: 25px;
}
</style>
