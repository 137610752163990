<template>
  <div class="h-100">
    <Layout ref="layout" :full="true">
      <template v-slot:body>
        <Crud :modal="true" @delete="$refs.datatable.update()" name="Log webhook" names="Log dos webhooks"
          @filter="$refs.datatable.applyFilters($event)" :btnfilter="false" :cancreate="false" url="/log-webhook">
          <template v-slot:list="form">
            <Datatable ref="datatable" :fields="fields" :url="'/log-webhook/query'" @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)" :where="where" :autoreload="1000"
              :iniorderby="{ column: 'created', type: 'DESC' }">
              <template v-slot:notificar_erro="data">
                <div class="text-center">
                  <i class="menu-icon tf-icons bx bx-check text-success" v-if="data.row.notificar_erro"></i><i
                    class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
              <template v-slot:data="data">
                <div class="text-center">
                  <i class="menu-icon tf-icons bx bx-show text-primary" style="cursor: pointer; font-size: 25px"
                    @click="showData(data.row)"></i>
                </div>
              </template>
              <template v-slot:response="data">
                <div class="text-center">
                  <i class="menu-icon tf-icons bx bx-show text-primary" style="cursor: pointer; font-size: 25px"
                    @click="showResponse(data.row)"></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input type="text" class="form-control" v-model="data.filters.name" />
              </div>
              <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
    <div class="modal fade" tabindex="-1" ref="modal_data">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Data</h5>
            <button type="button" class="btn-close" @click="showData(null)"></button>
          </div>
          <div class="modal-body" v-if="show" style="
              font-family: monospace;
              background-color: #272727;
              overflow: auto;
              max-height: calc(100vh - 300px);
              text-align: justify;
              white-space: pre;
            ">
            <div v-html="showJson(show.data)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_response">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Respostas</h5>
            <button type="button" class="btn-close" @click="showResponse(null)"></button>
          </div>
          <div class="modal-body" v-if="show" style="
              font-family: monospace;
              background-color: #272727;
              overflow: auto;
              max-height: calc(100vh - 300px);
              text-align: justify;
              white-space: pre;
            ">
            <div v-html="showJson(show.response)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      show: null,
      numeros: null,
      where: {
        name: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        level: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "URL", value: "url" },
        { name: "Method", value: "method" },
        { name: "Tipo", value: "type" },
        { name: "Status", value: "status" },
        { name: "Token", value: "token" },
        { name: "Data", value: "data", type: "slot" },
        { name: "Resposta", value: "response", type: "slot" },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
      ],
    };
  },
  methods: {
    showJson(data) {
      if (data) {
        return Prism.highlight(JSON.stringify(data, null, 2), Prism.languages.json, 'json');
      } else {
        return "";
      }
    },
    showData(v = null) {
      if (v) {
        this.show = v;
        $(this.$refs.modal_data).modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        $(this.$refs.modal_data).modal("hide");
        setTimeout(() => {
          this.show = null;
        }, 100);
      }
    },
    showResponse(v = null) {
      if (v) {
        this.show = v;
        $(this.$refs.modal_response).modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        $(this.$refs.modal_response).modal("hide");
        setTimeout(() => {
          this.show = null;
        }, 100);
      }
    },
  },
};
</script>
