<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <div>
          <div class="card">
            <h5
              class="card-header"
              @click="getDroplets()"
              style="cursor: pointer"
            >
              Droplets
            </h5>
            <div class="card-body">
              <div class="table-responsive text-nowrap">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>IP</th>
                      <th>Tags</th>
                      <th>Size</th>
                      <th>Preço (Hora/Mês)</th>
                      <th>Região</th>
                      <th>Status</th>
                      <th>Criado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in droplets" :key="i">
                      <td>{{ d.name }}</td>
                      <td>{{ getNetwork(d.networks.v4).ip_address }}</td>
                      <td>{{ d.tags }}</td>
                      <td>{{ d.size.slug }} - {{ d.size.disk }}GB</td>
                      <td>
                        {{ getPrice(d.size.price_hourly) }} -
                        {{ getPrice(d.size.price_monthly) }}
                      </td>
                      <td>{{ d.region.name }} - {{ d.region.slug }}</td>
                      <td>{{ d.status.toUpperCase() }}</td>
                      <td>{{ new Date(d.created_at).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                  <tfoot v-if="droplets.length">
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Preço total</th>
                      <th>
                        <h6 class="m-0">
                          U${{ getTotalPrice().h }} - U${{ getTotalPrice().m }}
                        </h6>
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <h5
              class="card-header"
              @click="getSshKeys()"
              style="cursor: pointer"
            >
              SSH Keys
            </h5>
            <div class="card-body">
              <div class="table-responsive text-nowrap">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>ID</th>
                      <th>Public key</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in sshKeys" :key="i">
                      <td>{{ d.name }}</td>
                      <td>{{ d.id }}</td>
                      <td>{{ d.public_key }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <h5
              class="card-header"
              @click="getRegions()"
              style="cursor: pointer"
            >
              Regiões
            </h5>
            <div class="card-body">
              <div class="table-responsive text-nowrap">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Slug</th>
                      <th>Sizes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in regions" :key="i">
                      <td>{{ d.name }}</td>
                      <td>{{ d.slug }}</td>
                      <td>
                        <span
                          :class="si > 0 && si % 8 == 0 ? 'd-block' : 'm-2'"
                          style="font-size: 14px"
                          v-for="(s, si) in d.sizes"
                          :key="si"
                        >
                          {{ s }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
  },
  data() {
    return {
      droplets: [],
      sshKeys: [],
      regions: [],
    };
  },
  methods: {
    getNetwork(net) {
      if (net == null) {
        return { ip_address: "-" };
      }
      var res = net[0];
      for (const v of net) {
        if (v.type == "public") {
          return v;
        }
      }
      return res;
    },

    getRegions() {
      this.regions = [];
      this.urlGet("/digitalocean/regions")
        .then((e) => {
          this.regions = e.data.regions;
        })
        .catch((e) => {});
    },
    getSshKeys() {
      this.sshKeys = [];
      this.urlGet("/digitalocean/sshkeys")
        .then((e) => {
          this.sshKeys = e.data.ssh_keys;
        })
        .catch((e) => {});
    },
    getTotalPrice() {
      var total = { h: 0, m: 0 };
      for (const d of this.droplets) {
        total.h += d.size.price_hourly;
        total.m += d.size.price_monthly;
      }
      return total;
    },
    getPrice(amount) {
      return amount;
    },
    getDroplets() {
      this.droplets = [];
      this.urlGet("/digitalocean/droplets")
        .then((e) => {
          this.droplets = e.data.droplets;
        })
        .catch((e) => {});
    },
  },
  mounted() {
    this.getDroplets();
    this.getSshKeys();
    this.getRegions();
  },
};
</script>
